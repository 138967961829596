<template>
  <nav v-if="requestData.last_page > 1">
    <ul class="pagination" style="float: right">
      <li
        class="page-item"
        :class="{ disabled: requestData.prev_page_url == null }"
      >
        <router-link
          class="page-link"
          :to="{
            name: namedPageRoute,
            params: { pageId: requestData.current_page - 1 },
          }"
          >Zurück</router-link
        >
      </li>
      <li
        class="page-item"
        :class="{ active: requestData.current_page == n }"
        v-for="n in parseInt(requestData.last_page)"
        :key="n"
      >
        <router-link
          class="page-link"
          :to="{ name: namedPageRoute, params: { pageId: n } }"
          >{{ n }}</router-link
        >
      </li>

      <li
        class="page-item"
        :class="{ disabled: requestData.next_page_url == null }"
      >
        <router-link
          class="page-link"
          :to="{
            name: namedPageRoute,
            params: { pageId: requestData.current_page + 1 },
          }"
          >Nächste</router-link
        >
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  props: {
    requestData: {
      required: true,
    },
    namedPageRoute: {
      required: true,
    },
  },
  data: function () {
    return {};
  },
};
</script>