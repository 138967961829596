<template>
  <BaseTemplate
    title="Dashboard"
    :breadCrumbData="[{ name: 'Dashboard', active: false, active: true }]"
  >
    <template v-slot:body>
      <div class="vapp">
        <Loader v-if="!ajaxHasLoaded" />
        <Card title="Dashboard">
          <template v-slot:tools> </template>
          <template v-slot:body>
            <Statusbalken
              :errors="errors"
              :infos="infos"
              :successes="successes"
            />
            <div class="row">
              <div class="col-md-6">
                <p class="text-center">
                  <strong>Zwischenstand EVK</strong>
                </p>
                <div class="progress-group">
                  Erreichte Kunden in EVK1
                  <span class="float-right"><b>160</b>/200</span>
                  <div class="progress progress-sm">
                    <div
                      class="progress-bar bg-primary"
                      style="width: 80%"
                    ></div>
                  </div>
                </div>
                <div class="progress-group">
                  Erreichte Kunden in EVK2
                  <span class="float-right"><b>150</b>/200</span>
                  <div class="progress progress-sm">
                    <div class="progress-bar bg-info" style="width: 75%"></div>
                  </div>
                </div>
                <!-- /.progress-group -->

                <div class="progress-group">
                  Erreichte Kunden in EVK3
                  <span class="float-right"><b>50</b>/200</span>
                  <div class="progress progress-sm">
                    <div
                      class="progress-bar bg-danger"
                      style="width: 25%"
                    ></div>
                  </div>
                </div>

                <!-- /.progress-group -->
                <div class="progress-group">
                  Erreichte Kunden in EVK4
                  <span class="float-right"><b>40</b>/200</span>
                  <div class="progress progress-sm">
                    <div
                      class="progress-bar bg-success"
                      style="width: 20%"
                    ></div>
                  </div>
                </div>

                <!-- /.progress-group -->
                <div class="progress-group">
                  Erreichte Kunden in EVK5
                  <span class="float-right"><b>20</b>/200</span>
                  <div class="progress progress-sm">
                    <div
                      class="progress-bar bg-warning"
                      style="width: 10%"
                    ></div>
                  </div>
                </div>
                <!-- /.progress-group -->
              </div>
              <div class="col-md-6">
                <div class="igFrameBar">
                  <div class="igData igData1"></div>
                  <div class="igData igData2"></div>
                  <div class="igData igData3"></div>
                  <div class="igData igData4"></div>
                  <div class="igData igData5"></div>
                  <div class="igData igData6"></div>
                  <div class="igData igData7"></div>
                  <div class="igData igData8"></div>
                  <div class="igData igData9"></div>
                  <div class="igData igData10"></div>
                  <div class="igData igData11"></div>
                  <div class="igData igData12"></div>
                </div>
              </div>
            </div>

            <!-- ./col -->
          </template>
          <template v-slot:footer>
            <!-- Footer (Datensätze?)-->
          </template>
        </Card>
      </div>
    </template>
  </BaseTemplate>
</template>


<script>
import axios from "axios";
//import DateRangePicker from "vue2-daterange-picker";
import Loader from "../micro/Loader.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";
import Statusbalken from "../micro/Statusbalken.vue";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://cetl.local";

export default {
  name: "Uebersicht",
  props: {
    msg: String,
  },
  components: { Loader, BaseTemplate, Card, Statusbalken },
  data() {
    return {
      listEntries: [],
      errors: [],
      infos: [],
      successes: [],
      ajaxHasLoaded: false,
      emptyDataset: false,
      idCounter: -1,
      filteredCount: -1, // manually updated by useFilter()-Method
      /* Datepicker Settings */
      opens: "auto", //Ja, dadurch öffnet es sich links..
      startDate: "2019-01-01",
      endDate: "2020-12-31",
      locale: {
        direction: "ltr", //direction of text
        format: "DD.MM.YYYY", //format of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        daysOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        monthNames: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ],
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      /* Ende Datepicker Settings */
      filterDateRange: {
        startDate:
          new Date().getFullYear() +
          "-" +
          (parseFloat(new Date().getUTCMonth()) + 1) +
          "-" +
          new Date().getUTCDate(),
        endDate:
          new Date().getFullYear() +
          "-" +
          (parseFloat(new Date().getUTCMonth()) + 1) +
          "-" +
          new Date().getUTCDate(),
      },
    };
  },
  methods: {
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled =
          date.getTime() < new Date().setDate(new Date().getDate() - 14);
      }
      return classes;
    },

    loadEntriesFromAPI() {
      let cthis = this;
      axios
        .get("/v1/stundenerfassung")
        .then(function (response) {
          if (response.status == 200) {
            if (response.data !== undefined) {
              cthis.listEntries = response.data;

              if (cthis.listEntries.length == 0) {
                cthis.emptyDataset = true;
              }

              // Wir müssen listEntries in Gruppen zerlegen, um auch optisch pro Tag gruppieren zu können..
              let groupedListEntries = []; // TODO: ID & Shizzle

              cthis.listEntries.forEach(function (item) {
                let hasSlot = false;
                groupedListEntries.forEach(function (gpItem) {
                  if (gpItem.id == item.range_from) {
                    item.isFiltered = false;
                    gpItem.data.push(item);
                    hasSlot = true;
                  }
                });
                if (!hasSlot) {
                  groupedListEntries.push({
                    id: item.range_from,
                    data: [item],
                    isFiltered: false,
                  });
                }
              });

              // Sortieren anhand der IDs
              groupedListEntries.sort(function (a, b) {
                return new Date(b.id) - new Date(a.id);
              });
              ///////////////////////
              groupedListEntries.forEach(function (gpSumItem) {
                ////window.console.log(gpSumItem);
                // .id => Datum
                // data push .id + Summe von Loads
                let sum = 0;
                gpSumItem.data.forEach(function (sumItem) {
                  sum += parseFloat(sumItem.uload);
                });
                gpSumItem.data.push({
                  id: --cthis.idCounter,
                  range_from: gpSumItem.id,
                  sumLoad: sum,
                  isFiltered: false,
                });
                sum = 0;
              });

              cthis.listEntries = groupedListEntries;

              //window.console.log(cthis.listEntries);
              cthis.ajaxHasLoaded = true;
            }
          }
        })
        .catch(function (error) {
          cthis.addMessage(
            "error",
            "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es in einigen Minuten erneut. (" +
              error +
              ")"
          );
          window.console.log(error);
        });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    useFilter() {
      let cthis = this;
      let start = new Date(this.filterDateRange.startDate);
      let end = new Date(this.filterDateRange.endDate);
      cthis.filteredCount = 0;

      this.listEntries.forEach(function (item) {
        if (new Date(item.id) < end && new Date(item.id) > start) {
          // in Suchbereich anzeigen
          item.isFiltered = false;
          cthis.filteredCount++;
          window.console.log(cthis.filteredCount);
        } else {
          item.isFiltered = true;
        }
      });
    },
    resetFilter() {
      this.filteredCount = -1;
      this.listEntries.forEach(function (item) {
        item.isFiltered = false;
      });
    },
  },
  filters: {
    visibleDate: function (d) {
      let dat = d.split("-");
      return dat[2] + "." + dat[1] + "." + dat[0];
    },
    dayOfWeek: function (d) {
      return new Date(d).getDay();
    },
    dayOfWeekShorthand: function (d) {
      return ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"][d];
    },
    absenceToVisible(a) {
      switch (a) {
        case "ABUB":
          return "Urlaub";
        case "ABKK":
          return "Krank";
        case "ABFT":
          return "Feiertag";
        case "ABAT":
          return "Ausgleichstag";
        case "ABSG":
          return "Sonstiges";
      }
    },
    intmeetingToVisible(im) {
      switch (im) {
        case "IMEV":
          return "EVK";
        case "IMVM":
          return "Vertriebsmeeting";
        case "IMPG":
          return "Personalgespräch";
        case "IMMS":
          return "Messen";
        case "IMSG":
          return "Sonstiges";
      }
    },
    officedayToVisible(od) {
      switch (od) {
        case "BTK":
          return "Telefonkontakt";
        case "BAD":
          return "Administration";
        case "BWT":
          return "Werkstatt";
        case "BSG":
          return "Sonstiges";
      }
    },
    visitationToVisible(vs) {
      switch (vs) {
        case "VEVK":
          return "EVK";
        case "VPOS":
          return "POS Check";
        case "VFB":
          return "Filialbesuch";
        case "VBB":
          return "Besuch";
        case "VJG":
          return "Jahresgespräch";
        case "VNP":
          return "Neudepot";
        case "VPS":
          return "Produktschulung";
        case "VVK":
          return "Verkaufseinsatz";
        case "VNK":
          return "Neukundenkontakt";
        case "VSG":
          return "Sonstiges";
      }
    },
    pickerdate: function (d) {
      let dat = new Date(d);
      return (
        dat.getUTCDate() +
        "." +
        (parseFloat(dat.getUTCMonth()) + 1) +
        "." +
        dat.getFullYear()
      );
    },
  },
  mounted: function () {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      window.console.log("mounted - uebersicht" + response);

      this.$nextTick(function () {
        let cthis = this;
        axios
          .get("/v1/user")
          .then(function () {
            //window.console.log("mounted - uebersicht - loaddata " + userdata);
            cthis.loadEntriesFromAPI();
          })
          .catch((err) => {
            window.console.log(err);
          });
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tbody tr:hover {
  background-color: darkgrey;
}
/* Dem ersten td der ersten tr ein border-top geben */
tr:first-child td {
  border-top: 1px solid black;
}
.bottomBorder {
  border-bottom: 1px solid black;
}

/* BarChart CSS */

.igFrameBar {
  width: 480px;
  height: 200px;
  position: relative;
  background: #f2f2f2;
  margin: 15% auto 0px auto;
  transform: translate(0, -50%);
}

.igFrameBar:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 50%;
}

.igFrameBar:after {
  content: "";
  width: 100%;
  height: 50%;
  background: transparent;
  position: absolute;
  top: 25%;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.igData {
  width: 20px;
  position: absolute;
  bottom: 1px;
  margin: 0px 0px 0px 0px;
  z-index: 99;
  float: left;
}

.igData1 {
  background: #9baec8;
  height: 0px;
  left: 26.3636363636px;
  animation: showBar1 1.2s 0.1s forwards;
}

.igData2 {
  background: #d9e1e8;
  height: 0px;
  left: 62.7272727273px;
  animation: showBar2 1.2s 0.2s forwards;
}

.igData3 {
  background: #2b90d9;
  height: 0px;
  left: 99.0909090909px;
  animation: showBar3 1.2s 0.3s forwards;
}

.igData4 {
  background: #8ec0e4;
  height: 0px;
  left: 135.4545454545px;
  animation: showBar4 1.2s 0.4s forwards;
}

.igData5 {
  background: #cadbe9;
  height: 0px;
  left: 171.8181818182px;
  animation: showBar5 1.2s 0.5s forwards;
}

.igData6 {
  background: #6aafe6;
  height: 0px;
  left: 208.1818181818px;
  animation: showBar6 1.2s 0.6s forwards;
}

.igData7 {
  background: #d6ecfa;
  height: 0px;
  left: 244.5454545455px;
  animation: showBar7 1.2s 0.7s forwards;
}

.igData8 {
  background: #a5dff9;
  height: 0px;
  left: 280.9090909091px;
  animation: showBar8 1.2s 0.8s forwards;
}

.igData9 {
  background: #dae9f4;
  height: 0px;
  left: 317.2727272727px;
  animation: showBar9 1.2s 0.9s forwards;
}

.igData10 {
  background: #d3e0f7;
  height: 0px;
  left: 353.6363636364px;
  animation: showBar10 1.2s 1s forwards;
}
.igData11 {
  background: #d3e0f7;
  height: 0px;
  left: 389.7272727275px;
  animation: showBar11 1.2s 1s forwards;
}
.igData12 {
  background: #d3e0f7;
  height: 0px;
  left: 426.0808080809px;
  animation: showBar12 1.2s 1s forwards;
}

.igData:before {
  position: absolute;
  top: -16px;
  font-size: 12px;
  color: #333;
  font-family: "roboto";
  font-weight: 300;
}

.igData1:before {
  content: "50";
  margin-left: 3px;
}

.igData2:before {
  content: "40";
  margin-left: 3px;
}

.igData3:before {
  content: "70";
  margin-left: 3px;
}

.igData4:before {
  content: "22";
  margin-left: 3px;
}

.igData5:before {
  content: "35";
  margin-left: 3px;
}

.igData6:before {
  content: "40";
  margin-left: 3px;
}

.igData7:before {
  content: "20";
  margin-left: 3px;
}

.igData8:before {
  content: "36";
  margin-left: 3px;
}

.igData9:before {
  content: "45";
  margin-left: 3px;
}

.igData10:before {
  content: "62";
  margin-left: 3px;
}
.igData11:before {
  content: "30";
  margin-left: 3px;
}
.igData12:before {
  content: "10";
  margin-left: 3px;
}

.igData:after {
  position: absolute;
  bottom: -55px;
  transform: rotate(30deg);
  color: #666;
  font-size: 14px;
  text-align: left;
  font-family: "roboto";
  font-weight: 300;
  width: 150px;
}

.igData1:after {
  content: "Januar";
}

.igData2:after {
  content: "Februar";
}

.igData3:after {
  content: "März";
}

.igData4:after {
  content: "April";
}

.igData5:after {
  content: "Mai";
}

.igData6:after {
  content: "Juni";
}

.igData7:after {
  content: "Juli";
}

.igData8:after {
  content: "August";
}

.igData9:after {
  content: "September";
}

.igData10:after {
  content: "Oktober";
}
.igData11:after {
  content: "Novemer";
}
.igData12:after {
  content: "Dezember";
}

@keyframes showBar1 {
  0% {
    height: 0px;
  }
  100% {
    height: 50%;
  }
}
@keyframes showBar2 {
  0% {
    height: 0px;
  }
  100% {
    height: 40%;
  }
}
@keyframes showBar3 {
  0% {
    height: 0px;
  }
  100% {
    height: 70%;
  }
}
@keyframes showBar4 {
  0% {
    height: 0px;
  }
  100% {
    height: 22%;
  }
}
@keyframes showBar5 {
  0% {
    height: 0px;
  }
  100% {
    height: 35%;
  }
}
@keyframes showBar6 {
  0% {
    height: 0px;
  }
  100% {
    height: 40%;
  }
}
@keyframes showBar7 {
  0% {
    height: 0px;
  }
  100% {
    height: 20%;
  }
}
@keyframes showBar8 {
  0% {
    height: 0px;
  }
  100% {
    height: 36%;
  }
}
@keyframes showBar9 {
  0% {
    height: 0px;
  }
  100% {
    height: 45%;
  }
}
@keyframes showBar10 {
  0% {
    height: 0px;
  }
  100% {
    height: 62%;
  }
}
@keyframes showBar11 {
  0% {
    height: 0px;
  }
  100% {
    height: 30%;
  }
}
@keyframes showBar12 {
  0% {
    height: 0px;
  }
  100% {
    height: 10%;
  }
}
</style>

<style>
.daterangepicker {
  top: 42px !important;
}
</style>