<template>
<div>
  <div
    class="modal fade show"
    style="display: block; padding-right: 17px"
    aria-modal="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"><slot name="title"></slot></h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closed"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-default" data-dismiss="modal"             @click="closed"
>
            Schließen
          </button>
          <button @click="confirmed" type="button" :class="'btn btn-' + buttonType">
            {{ oktext }}
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <div class="modal-backdrop fade show"></div>
</div>
</template>
<script>
export default {
  name: "Modal",
  props: {
    oktext: {
      required: true,
    },
    buttonType: {
      required: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {
      confirmed: function(){
          this.$emit('confirmed', null);
      },
      closed: function(){
          this.$emit('closed',true);
      },
  }
};
</script>
<style scoped>
</style>