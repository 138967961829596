<template>
  <!-- Todo: Methoden überprüfen und Variablen einfügen -->
  <BaseTemplate
    :breadCrumbData="[
      { name: 'Dashboard', active: false, to: $defaultDashboard },
      { name: 'Benutzerverwaltung', active: false, to: '/useradmin' },
      { name: breadCrumbActiveString, active: true, to: null },
    ]"
  >
    <template v-slot:title>
      <span v-if="mode == 'add'">Benutzer hinzufügen</span>
      <span v-if="mode == 'edit'">Benutzer bearbeiten</span>
    </template>
    <template v-slot:body>
      <Card>
        <template v-slot:title>
          <span v-if="mode == 'add'">Benutzer hinzufügen</span>
          <span v-if="mode == 'edit'">Benutzer bearbeiten</span>
        </template>
        <template v-slot:body>
          <Loader v-if="!ajaxHasLoaded" />
          <Statusbalken
            :errors="errors"
            :infos="infos"
            :successes="successes"
          />
          <div v-if="ajaxHasLoaded">
            <div class="row">
              <div class="col-12">
                <label for="usernameNA">Benutzername</label>
              </div>
            </div>
            <div class="row rowInput">
              <div class="col-12">
                <input
                  class="form-control"
                  type="text"
                  name="usernameNA"
                  v-model="user.name"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="store">E-Mail Adresse (Login-Name)</label>
              </div>
            </div>
            <div class="row rowInput">
              <div class="col-12">
                <input
                  class="form-control"
                  type="text"
                  name="email"
                  v-model="user.email"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="passwordNA">Passwort</label>
              </div>
            </div>
            <div class="row rowInput">
              <div class="col-12">
                <input
                  class="form-control"
                  type="password"
                  name="passwordNA"
                  v-model="user.password"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="passwordagain">Passwort wiederholen</label>
              </div>
            </div>
            <div class="row rowInput">
              <div class="col-12">
                <input
                  class="form-control"
                  type="password"
                  name="passwordagainNA"
                  v-model="user.passwordagain"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label for="role">Rolle</label>
              </div>
            </div>
            <div class="row rowInput">
              <div class="col-12">
                <select class="form-control" name="role" v-model="user.role_id">
                  <!-- Todo.. Rollen evtl. nicht fixed machen, sondern in die DB schreiben.. -->
                  <template v-for="r in roles">
                    <option :key="r.id" :value="r.id">
                      {{ r.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div style="height: 20px"></div>
            <div class="row">
              <div class="col-12">
                <button
                  v-if="mode == 'add'"
                  class="btn btn-success"
                  @click="addUser"
                >
                  Benutzer hinzufügen
                </button>
                <button
                  v-if="mode == 'edit'"
                  class="btn btn-primary"
                  @click="updateUser"
                >
                  Benutzer speichern
                </button>
              </div>
            </div>

            <!-- Ende Modal für das Hinzufügen neuer Benutzer -->
          </div>
        </template>
      </Card>
    </template>
  </BaseTemplate>
</template>

<script>
import Statusbalken from "../micro/Statusbalken.vue";
import Loader from "../micro/Loader.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";

export default {
  name: "Rollenverwaltung",
  props: {},
  components: { Statusbalken, Loader, BaseTemplate, Card },
  data() {
    return {
      mode: "add",
      roleid: "",
      user: {
        name: "",
        password: "",
        passwordagain: "",
        roleid: "",
      },
      errors: [],
      infos: [],
      successes: [],
      breadCrumbActiveString: "Benutzer hinzufügen",
      ajaxHasLoaded: false,
    };
  },
  watch: {
    mode: function () {
      if (this.mode == "edit") {
        this.breadCrumbActiveString = "Benutzer bearbeiten";
      } else {
        this.breadCrumbActiveString = "Benutzer hinzufügen";
      }
    },
  },
  methods: {
    validateAddUser() {
      this.clearMessages();
      this.validationHelper();
      if (this.user.password == "" || this.user.password.length < 8) {
        this.addMessage(
          "error",
          "Das angegebene Passwort ist zu kurz. Das Passwort muss mindestens 8 Zeichen lang sein."
        );
      }
      if (!/\d/.test(this.user.password)) {
        this.addMessage(
          "error",
          "Das Passwort muss mindestens eine Ziffer enthalten"
        );
      }
    },
    addUser: function () {
      this.validateAddUser();
      if (this.errors.length > 0) return;

      let cthis = this;
      // welp.
      this.clearMessages();
      this.$axios.get("/sanctum/csrf-cookie").then((response) => {
        window.console.log(response);
        cthis.$axios
          .post("/v1/useradministration", cthis.user)
          .then(function () {
            cthis.addMessage("success", "Benutzer wurde hinzugefügt");
          })
          .catch(function (resp) {
            cthis.addMessage(
              "error",
              "Fehler beim Hinzufügen des Benutzers (" +
                resp.response.data.error +
                ")"
            );
          })
          .finally(function () {
            //cthis.fetchUserlist();
          });
      });
    },

    validateUpdateUser() {
      // reset vor Validierung
      this.clearMessages();

      if (this.user.password.length > 0 && this.user.password.length < 8) {
        this.addMessage(
          "error",
          "Das angegebene Passwort ist zu kurz. Das Passwort muss mindestens 8 Zeichen lang sein."
        );
      }
      if (this.user.password.length > 0) {
        if (!/\d/.test(this.user.password)) {
          this.addMessage(
            "error",
            "Das Passwort muss mindestens eine Ziffer enthalten"
          );
        }
      }
      this.validationHelper();
    },
    updateUser: function () {
      this.validateUpdateUser();

      if (this.errors.length > 0) return;

      let cthis = this;
      this.clearMessages();
      this.$axios.get("/sanctum/csrf-cookie").then((response) => {
        window.console.log(response);
        cthis.$axios
          .put("/v1/useradministration/" + cthis.$route.params.id, cthis.user)
          .then(function () {
            cthis.addMessage("success", "Änderungen wurden gespeichert.");
          })
          .catch(function (resp) {
            cthis.addMessage(
              "error",
              "Beim Speichern der Änderungen ist ein Fehler aufgetreten (" +
                resp.response.data.error +
                ")"
            );
          })
          .finally(function () {
            //cthis.fetchUserlist();
          });
      });
    },
    fetchrolelist() {
      let cthis = this;

      this.$axios
        .get("/v1/roleadministration")
        .then((sentdata) => {
          // Onload: Einträge laden
          this.roles = sentdata.data;
          cthis.ajaxHasLoaded = true;
        })
        .catch(function () {
          cthis.addMessage("error", "Fehler beim Laden der Rollen");
        })
        .finally(function () {});
    },
    loadUser() {
      let cthis = this;
      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        //window.console.log(response);
        cthis.$axios
          .get("/v1/useradministration/" + cthis.$route.params.id)
          .then((sentdata) => {
            cthis.user = sentdata.data;
            cthis.ajaxHasLoaded = true;
          })
          .catch(function () {
            cthis.addMessage("error", "Fehler beim Laden der Rollenliste");
          })
          .finally(function () {});
      });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    clearMessages() {
      this.infos = [];
      this.errors = [];
      this.successes = [];
    },
    validateDeleteRole(uid) {
      window.console.log(uid);
      // Todo: Berechtigungscheck oder dergleichen?
    },
    validateUpdateRole() {
      this.errors = []; // reset vor Validierung
      this.validationHelper();
    },
    validateAddRole() {
      this.errors = [];
      this.validationHelper();
    },
    validationHelper() {
      if (this.user.role_id == "") {
        this.addMessage("error", "Das Feld Rollenname ist ein Pflichtfeld");
      }
    },
  },
  mounted: function () {
    let cthis = this;
    this.$nextTick(function () {
      this.fetchrolelist();
      if (cthis.$route.params.id != undefined) {
        cthis.mode = "edit";
        cthis.loadUser();
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tbody tr:hover {
  background-color: rgb(189, 187, 187);
}
td {
  vertical-align: middle;
}
.rowInput {
  margin-bottom: 10px;
}
</style>
