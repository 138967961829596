<template>
  <div>
    <BaseTemplate
      title="Benutzereinstellungen"
      :breadCrumbData="[
        { name: 'Dashboard', active: false, to: $defaultDashboard },
        { name: 'Benutzereinstellungen', active: true, to: null },
      ]"
    >
      <template v-slot:body>
        <Card title="Benutzereinstellungen des aktuellen Benutzers">
          <template v-slot:body>
            <Statusbalken
            :errors="errors"
            :infos="infos"
            :successes="successes"
          />
            <div class="row">
              <div class="col-6">
                <p>
                  <label for="newpw">Neues Passwort</label>
                  <input
                    class="form-control"
                    type="password"
                    name="newpw"
                    v-model="newpw"
                  />
                </p>
              </div>
              <div class="col-6">
                <p>
                  <label for="newpwagain">Neues Passwort wiederholen</label>
                  <input
                    class="form-control"
                    type="password"
                    name="newpwagain"
                    v-model="newpwagain"
                  />
                </p>
              </div>
            </div>
            <button class="btn btn-success" @click="savePassword">
              Passwort speichern
            </button>
            <div style="height: 2vh"></div>
            <div class="row">
              <div class="col-12">
                <p>
                  <label for="email">Login Emailadresse ändern</label>
                  <input
                    class="form-control"
                    type="email"
                    name="email"
                    v-model="email"
                  />
                </p>
              </div>
            </div>
            <button class="btn btn-success" @click="saveEmail">
              Emailadresse speichern
            </button>
          </template>
        </Card>
      </template>
    </BaseTemplate>
  </div>
</template>

<script>
import axios from "axios";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";
import Statusbalken from "../micro/Statusbalken.vue";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://cetl.local";

export default {
  name: "Benutzereinstellungen",
  props: {},
  components: { BaseTemplate, Card, Statusbalken },
  data() {
    return {
      newpw: "",
      newpwagain: "",
      email: "",
      errors: [],
      infos: [],
      successes: [],
    };
  },
  methods: {
    savePassword() {
      let cthis = this;
      this.errors = [];
      this.successes = [];

      if (this.newpw !== this.newpwagain) {
        this.addMessage("error", "Die beiden Passwörter sind nicht gleich.");
      }
      if (this.newpw.length > 0 && this.newpw.length < 8) {
        this.addMessage(
          "error",
          "Das angegebene Passwort ist zu kurz. Das Passwort muss mindestens 8 Zeichen lang sein."
        );
      }
      if (this.newpw.length > 0) {
        if (!/\d/.test(this.newpw)) {
          this.addMessage(
            "error",
            "Das Passwort muss mindestens eine Ziffer enthalten"
          );
        }
      } else {
        this.addMessage(
          "error",
          "Das Feld Passwort ist ein Pflichtfeld und muss daher angegeben werden."
        );
      }
      if (this.errors.length > 0) return;

      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        cthis.$axios
          .put("/v1/usersettings", {
            password: cthis.newpw,
          })
          .then(function () {
            cthis.addMessage("success", "Einstellungen gespeichert.");
          })
          .catch(function (out) {
            cthis.addMessage(
              "error",
              "Beim Speichern der Einstellungen ist ein Fehler aufgetreten (" +
                out.response.data.error +
                ")"
            );
          })
          .finally(function () {});
      });
    },
    saveEmail() {
      this.errors = [];
      this.successes = [];
      if (this.email == "" || !/\S+@\S+/.test(this.email)) {
        this.addMessage(
          "error",
          "Das Feld Email ist ein Pflichtfeld. Bitte geben Sie eine gültige E-Mail-Adresse an"
        );
      }
      if (this.errors.length > 0) return;

      let cthis = this;
      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        cthis.$axios
          .put("/v1/usersettings", {
            email: cthis.email,
          })
          .then(function () {
            cthis.addMessage("success", "E-Mail-Adresse gespeichert.");
          })
          .catch(function (out) {
            cthis.addMessage(
              "error",
              "Beim Speichern der E-Mail-Adresse ist ein Fehler aufgetreten (" +
                out.response.data.error +
                ")"
            );
          })
          .finally(function () {});
      });
    },
    backToList() {
      this.$emit("backToList", null);
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
