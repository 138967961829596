<template>
  <BaseTemplate
    title="Kundenverwaltung"
    :breadCrumbData="[
      { name: 'Dashboard', active: false, to: $defaultDashboard },
      { name: 'Kundenverwaltung', active: true, to: null },
    ]"
  >
    <template v-slot:body>
      <Loader v-if="!ajaxHasLoaded" />
      <Card title="Kunden">
        <template v-slot:tools>
          <router-link to="/customers/create" class="btn btn-sm btn-success"
            ><i class="fas fa-plus"></i> Kunde hinzufügen</router-link
          >
        </template>

        <template v-slot:body>
          <div>
            <DeleteModal
              v-if="modalVisible"
              @confirmed="deleteUserFromEnv()"
              @closed="modalVisible = false"
            />
            <Statusbalken
              :errors="errors"
              :infos="infos"
              :successes="successes"
            />

            <div class="row">
              <div class="col-12">
                <div class="btn btn-primary" @click="showFilter = true">
                  <i class="fas fa-filter"></i>
                  Kunde suchen
                </div>
              </div>
            </div>
            <div style="height:20px;"></div>
            <div class="row">
              <div class="col-12" v-if="showFilter">
                
                

              <div class="row">
                <div class="col-4">
                  <label for="companyname">Firmenname Kurz</label>
                </div>
                <div class="col-4">
                  <label for="companyname">Firmenname Lang</label>
                </div>
                <div class="col-4">
                  <label for="group">Gruppe {{ searchGroup }}</label>
                </div>
              </div>

              <div class="row rowInput">
                <div class="col-4">
                  <input
                    class="form-control"
                    type="text"
                    name="companyname"
                    v-model="searchCompanyShort"
                  />
                </div>
                <div class="col-4">
                  <input
                    class="form-control"
                    type="text"
                    name="companyname"
                    v-model="searchCompanyFull"
                  />
                </div>
                <div class="col-4">
                  <select
                    v-model="searchGroup"
                    name="group"
                    class="form-control"
                  >
                    <option></option>
                    <option
                      v-for="group in groups"
                      :key="group.id"
                      :value="group.name"
                    >
                      {{ group.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-6"><label for="store">Kundennummer</label></div>
                <div class="col-6">
                  <label for="street">Straße und Hausnummer</label>
                </div>
              </div>

              <div class="row rowInput">
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="store"
                    v-model="searchCustomerNumber"
                  />
                </div>
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="street"
                    v-model="searchStreet"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6"><label for="zip">Postleitzahl</label></div>
                <div class="col-6"><label for="town">Ort</label></div>
              </div>
              <div class="row rowInput">
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="zip"
                    v-model="searchZip"
                  />
                </div>
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="town"
                    v-model="searchTown"
                  />
                </div>
              </div>
              <div style="height: 2vh"></div>

              <table class="table">
                <thead>
                  <th>Kurzbezeichnung</th>
                  <th>Firmenname</th>
                  <th>Kundennummer</th>
                  <th>Straße</th>
                  <th>Postleitzahl</th>
                  <th>Ort</th>
                  <th>Gruppe</th>
                </thead>
                <tbody>
                  <tr
                    v-for="n in filteredList"
                    :key="n.id"
                    @click="selectCustomer(n)"
                  >
                    <td>{{ n.company_name_short }}</td>
                    <td>{{ n.company_name_full }}</td>
                    <td>{{ n.customer_number }}</td>
                    <td>{{ n.street }}</td>
                    <td>{{ n.zip }}</td>
                    <td>{{ n.town }}</td>
                    <td>
                      <span v-if="n.group != null">{{ n.group.name }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>







                
              </div>
            </div>

            <div v-if="ajaxHasLoaded">
              <div v-if="listEntries.length > 0">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nr.</th>
                      <th>Firmenname kurz</th>
                      <th>Straße</th>
                      <th>PLZ</th>
                      <th>Stadt</th>
                      <th>Land</th>
                      <th>Gruppe</th>

                      <th>
                        &nbsp;
                        <select
                          name="paginateSize"
                          v-model="paginateSize"
                          class="form-control"
                          style="
                            width: 100%;
                            max-width: 150px;
                            height: 30px;
                            padding: 0;
                          "
                        >
                          <option value="25">Einträge pro Seite</option>
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="n in listEntries">
                      <tr :key="n.id">
                        <td>
                          <span>
                            {{ n.id }}
                          </span>
                        </td>
                        <td>
                          <span>{{ n.company_name_short }}</span>
                        </td>
                        <td>
                          <span>{{ n.street }}</span>
                        </td>
                        <td>
                          <span>{{ n.zip }}</span>
                        </td>
                        <td>
                          <span>{{ n.town }}</span>
                        </td>
                        <td>
                          <span>{{ n.country }}</span>
                        </td>

                        <td>
                          <span v-if="n.group != null">{{ n.group.name }}</span>
                        </td>

                        <td style="vertical-align: top">
                          <div class="btn-group">
                            <router-link
                              :to="'/customers/' + n.id"
                              class="btn btn-sm btn-primary"
                              ><i class="fas fa-pen"></i
                            ></router-link>
                            <button
                              class="btn btn-sm btn-danger"
                              @click="copyToEnv(n)"
                              :title="'Kunde ' + n.name + ' löschen'"
                            >
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <Seitenzahlen
              :requestData="paginationData"
              namedPageRoute="customerspage"
            />
          </div>
        </template>
      </Card>
    </template>
  </BaseTemplate>
</template>

<script>
import Loader from "../micro/Loader.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";
import DeleteModal from "../micro/DeleteModal.vue";
import Statusbalken from "../micro/Statusbalken.vue";
import Seitenzahlen from "../micro/Seitenzahlen.vue";
//import Kundenwahl from "../rich/Kundenwahl.vue";

export default {
  name: "Kundeeinstellungen",
  props: {},
  components: {
    Loader,
    BaseTemplate,
    Card,
    DeleteModal,
    Statusbalken,
    Seitenzahlen,
    //    Kundenwahl,
  },
  data() {
    return {
      usersLoaded: false,
      rolesLoaded: false,
      mode: "add",
      userid: "",
      password: "",
      passwordagain: "",
      email: "",
      username: "",
      role: "",
      roles: [],
      modalAddUserVisible: false,
      listEntries: [], // [{ id: 1, name: "foo", email: "bar", role: "admin" }],
      errors: [],
      infos: [],
      successes: [],
      envData: {},
      modalVisible: false,
      ajaxHasLoaded: false,
      paginationData: {},
      paginateSize: 25,
      showFilter: false,
    };
  },
  watch: {
    $route: function () {
      this.init();
    },
    paginateSize: function () {
      this.init();
    },
  },
  methods: {
    copyToEnv: function (payload) {
      this.envData = payload;
      this.modalVisible = true;
    },
    setRouteData: function (data) {
      this.listEntries = data["users"];
      this.roles = data["roles"];
    },
    fetchUserlist() {
      let cthis = this;
      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        let pageNr = "";

        if (cthis.$route.params.pageId == undefined) {
          cthis.$route.params.pageId = 1;
        }
        pageNr =
          "?page=" + cthis.$route.params.pageId + "&size=" + cthis.paginateSize;

        cthis.$axios
          .get("/v1/customers" + pageNr)
          .then((sentdata) => {
            // Onload: Einträge laden
            cthis.listEntries = sentdata.data.data;
            cthis.paginationData = sentdata.data; // Request insgesamt (enthält u.u Meta Attribute bei DTOs)
            cthis.ajaxHasLoaded = true;
          })
          .catch(function () {
            cthis.addMessage("error", "Fehler beim Laden der Kundeliste");
            return;
          });
      });
    },
    deleteUserFromEnv() {
      let obj = this.envData;
      this.validateDeleteUser(obj.id);

      this.clearMessages();
      this.mode = "deletion";
      // Löschen
      let cthis = this;

      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        cthis.$axios
          .delete("/v1/useradministration/" + obj.id)
          .then(function () {
            cthis.addMessage("success", "Kunde wurde gelöscht");
          })
          .catch(function (resp) {
            cthis.addMessage(
              "error",
              "Der Kunde konnte nicht gelöscht werden (" +
                resp.response.data.error +
                ")"
            );
          })
          .finally(function () {
            cthis.fetchUserlist();
            cthis.mode = "";
            cthis.modalVisible = false;
          });
      });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    clearMessages() {
      this.infos = [];
      this.errors = [];
      this.successes = [];
    },
    validateDeleteUser(uid) {
      window.console.log(uid);
      // Todo: Berechtigungscheck oder dergleichen?
    },
    init() {
      this.fetchUserlist();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.init();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tbody tr:hover {
  background-color: darkgrey;
}
td {
  vertical-align: middle;
}
.deleteButton {
  padding: 5px 16px;
  margin-top: 0;
}

/* The Modal (background) */
.modal {
  /*display: none;*/ /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: red;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* P-Abstand fürs Modal reduzieren */

.modal-content p {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
