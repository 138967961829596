<template>
  <div id="app">
    
<!--
    <LoginSeite
      v-if="!authenticated && loaded"
      @success="
        authenticated = true;
        loadPermissions();
      "
    /> -->
    <!--<div v-if="authenticated">-->
      <!-- Permissions mittels :permissions reinladen. Testfunktion siehe -->
      <!--<Topbar
        v-if="loaded"
        :permissions="myUserPermissions"
        @logout="authenticated = false"
      />-->


      <router-view></router-view>
    <!--</div>-->
  </div>
</template>

<script>
export default {
  name: "app",
  components: {
  //  Topbar,
  },

  data() {
    return {
      mainState: "uebersicht",
      currentObject: {},
      operationMode: "create",
      authenticated: false,
      loaded: false,
      myUserPermissions: [],
    };
  },
  methods: {
    showEntryPage(e) {
      this.currentObject = e;
      this.mainState = "stundenerfassung";
      this.operationMode = "edit";
    },
    showCreateNewEntry() {
      this.currentObject = null;
      this.mainState = "stundenerfassung";
      this.operationMode = "create";
    },
    showBenutzereinstellungen() {
      this.currentObject = null;
      this.mainState = "benutzereinstellungen";
      this.operationMode = "none";
    },
    showBenutzerverwaltung() {
      this.mainState = "benutzerverwaltung";
    },
    showGruppenverwaltung() {
      this.mainState = "rollenverwaltung";
    },
    backToList() {
      this.mainState = "uebersicht";
    },
    // also used in main.js!
    checkSessionStatus() {

      let cthis = this;
      this.$axios
        .get("/v1/user")
        .then((userdata) => {
          if (userdata instanceof Object) {
          //  this.authenticated = true;
            this.loaded = true;
          }
        })
        .catch(function () {
          //cthis.authenticated = false;
          cthis.loaded = true;
          window.console.log("Nicht eingeloggt.");

        });
    },
    loadPermissions() {
      this.permissions = [];
      let cthis = this;
      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        cthis.$axios
          .get("/v1/getMyPermissions")
          .then(function (data) {
            for (let p in data.data[0].permissions) {
              let permission = data.data[0].permissions[p];
              cthis.myUserPermissions.push(permission.name);
            }
          })
          .catch(function () {});
      });
    },
    hasPermission(perm) {
      return this.myUserPermissions.indexOf(perm) > -1;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      //window.console.log("mounted");

      this.loadPermissions(); // Damit beim reload trotzdem noch Permissions da sind..

      this.checkSessionStatus();
      /*
      setInterval(
        function () {
          if (this.loaded && this.authenticated !== false)
            this.checkSessionStatus();
        }.bind(this),
        300000
      ); // alle 300 Sekunden die Sitzung überprüfen (5min)
      */
    });
  },
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
div > h3 {
  height: 100%;
}
h3 {
  margin-top: 28%;
  margin-left: 1%;
  font-size: 50px;
}
</style>
<style>
/* Global Styles */
.appLoader {
  position: absolute;
  top: 40%;
  left: 30%;
  font-size: 50px;
}
.pointer {
  cursor: pointer;
}
.underline {
  text-decoration: underline;
}
</style>
<!-- Imported from: Stundenerfassung.vue -->

<style>
/*input,
select,
textarea,
button {
  border-radius: 3px;
  margin-top: 10px;
}
*//*
html {
  overflow-y: scroll;
}*/
input[readonly],
textarea[readonly],
select[readonly] {
  cursor: not-allowed;
}
input:disabled,
select:disabled,
textarea:disabled {
  background-color: #e9e8ea;
  color: #515151;
}
/* Damit das input über die ganze Seite läuft */
.vue-daterange-picker {
  width: 100%;
  padding: 0;
}
/* Year-Selektor ist im Single-Datepicker-Modus viel zu breit*/
.yearselect {
  width: 60px;
}
/* Error/Success-Box formatieren */
.statebox {
  border-radius: 3px;
  min-height: 50px;
  padding-top: 1.5vh;
  font-weight: bold;
}
/* Datepicker-Div ausblenden & Padding nullen, damit das Layout gewahrt bleibt. */
.reportrange-text {
  border: none !important;
  padding: 0 !important;
}
/* Der untere Button soll etwas Abstand zum Seitenrand einhalten. */

/* Info-Bubble */
/*.hiddenBubble {
  display: none;
}
.trigger:hover + .hiddenBubble {
  display: block;
  position: absolute;
  left: 0px;
  top: -40px;
  max-width: 25vw;
  word-wrap: break;
  font-size: 12px;
  background-color: #000;
  padding: 1vh;
  color: white;
  border-radius: 3px;
}*/

/* Für Info-Bubbles */
.prelative {
  position: relative;
}
</style>