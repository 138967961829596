import { render, staticRenderFns } from "./Kundenverwaltung.vue?vue&type=template&id=22b5f7e2&scoped=true&"
import script from "./Kundenverwaltung.vue?vue&type=script&lang=js&"
export * from "./Kundenverwaltung.vue?vue&type=script&lang=js&"
import style0 from "./Kundenverwaltung.vue?vue&type=style&index=0&id=22b5f7e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22b5f7e2",
  null
  
)

export default component.exports