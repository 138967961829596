<template>
  <BaseTemplate
    title="Markenverwaltung"
    :breadCrumbData="[
      { name: 'Dashboard', active: false, to: $defaultDashboard },
      { name: 'Markenverwaltung', active: true, to: null },
    ]"
  >
    <template v-slot:body>
      <Loader v-if="!ajaxHasLoaded" />
      <Card title="Marken">
        <template v-slot:tools>
          <router-link to="/marks/create" class="btn btn-sm btn-success"
            ><i class="fas fa-plus"></i> Marke hinzufügen</router-link
          > 
        </template>

        <template v-slot:body>
          <div>
            <DeleteModal
              v-if="modalVisible"
              @confirmed="deleteUserFromEnv()"
              @closed="modalVisible = false"
            />
            <Statusbalken
              :errors="errors"
              :infos="infos"
              :successes="successes"
            />
            <div v-if="ajaxHasLoaded">
              
              <div v-if="listEntries.length > 0">
                
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nr.</th>
                      <th>Markenname</th>
                      <th>Position</th>
                      <th>
                        &nbsp;
                          <select name="paginateSize" v-model="paginateSize" class="form-control" style="width:100%; max-width:150px;height:30px; padding:0">
                          <option value="25">Einträge pro Seite</option>
                          <option>25</option>
                          <option>50</option>
                          <option>100</option>
                        </select> 
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="n in listEntries">
                      <tr :key="n.id">
                        <td>
                          <span>
                            {{ n.id }}
                          </span>
                        </td>
                        <td>
                          <span>{{ n.name }}</span>
                        </td>
                        <td>
                          <span>{{n.position}}</span>
                        </td>
                        
                        <td style="vertical-align: top">
                          <div class="btn-group">
                            <router-link
                              :to="'/marks/' + n.id"
                              class="btn btn-sm btn-primary"
                              ><i class="fas fa-pen"></i
                            ></router-link>
                            <button
                              class="btn btn-sm btn-danger"
                              @click="copyToEnv(n)"
                              :title="'Marken ' + n.name + ' löschen'"
                            >
                              <i class="fas fa-trash"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
            <Seitenzahlen
              :requestData="paginationData"
              namedPageRoute="markspage"
            />
          </div>
        </template>
      </Card>
    </template>
  </BaseTemplate>
</template>

<script>
import Loader from "../micro/Loader.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";
import DeleteModal from "../micro/DeleteModal.vue";
import Statusbalken from "../micro/Statusbalken.vue";
import Seitenzahlen from "../micro/Seitenzahlen.vue";

export default {
  name: "Markeneinstellungen",
  props: {},
  components: {
    Loader,
    BaseTemplate,
    Card,
    DeleteModal,
    Statusbalken,
    Seitenzahlen,
  },
  data() {
    return {
      usersLoaded: false,
      rolesLoaded: false,
      mode: "add",
      userid: "",
      password: "",
      passwordagain: "",
      email: "",
      username: "",
      role: "",
      roles: [],
      modalAddUserVisible: false,
      listEntries: [], // [{ id: 1, name: "foo", email: "bar", role: "admin" }],
      errors: [],
      infos: [],
      successes: [],
      envData: {},
      modalVisible: false,
      ajaxHasLoaded: false,
      paginationData: {},
      paginateSize: 25,
    };
  },
  watch: {
    $route: function () {
      this.init();
    },
    paginateSize: function(){
      this.init();
    }
  },
  methods: {
    copyToEnv: function (payload) {
      this.envData = payload;
      this.modalVisible = true;
    },
    setRouteData: function (data) {
      this.listEntries = data["users"];
      this.roles = data["roles"];
    },
    fetchMarklist() {
      let cthis = this;
      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        let pageNr = "";

        if (cthis.$route.params.pageId == undefined) {
          cthis.$route.params.pageId = 1;
        }
        pageNr =
          "?page=" + cthis.$route.params.pageId + "&size=" + cthis.paginateSize;

        cthis.$axios
          .get("/v1/marks" + pageNr)
          .then((sentdata) => {
            // Onload: Einträge laden
            cthis.listEntries = sentdata.data.data;
            cthis.paginationData = sentdata.data; // Request insgesamt (enthält u.u Meta Attribute bei DTOs)
            cthis.ajaxHasLoaded = true;
          })
          .catch(function () {
            cthis.addMessage("error", "Fehler beim Laden der Markenliste");
            return;
          });
      });
    },
    deleteUserFromEnv() {
      let obj = this.envData;
      this.validateDeleteUser(obj.id);

      this.clearMessages();
      this.mode = "deletion";
      // Löschen
      let cthis = this;

      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        cthis.$axios
          .delete("/v1/useradministration/" + obj.id)
          .then(function () {
            cthis.addMessage("success", "Marken wurde gelöscht");
          })
          .catch(function (resp) {
            cthis.addMessage(
              "error",
              "Der Marken konnte nicht gelöscht werden (" +
                resp.response.data.error +
                ")"
            );
          })
          .finally(function () {
            cthis.fetchMarklist();
            cthis.mode = "";
            cthis.modalVisible = false;
          });
      });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    clearMessages() {
      this.infos = [];
      this.errors = [];
      this.successes = [];
    },
    validateDeleteUser(uid) {
      window.console.log(uid);
      // Todo: Berechtigungscheck oder dergleichen?
    },
    init() {
      this.fetchMarklist();
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.init();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tbody tr:hover {
  background-color: darkgrey;
}
td {
  vertical-align: middle;
}
.deleteButton {
  padding: 5px 16px;
  margin-top: 0;
}

/* The Modal (background) */
.modal {
  /*display: none;*/ /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: red;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* P-Abstand fürs Modal reduzieren */

.modal-content p {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
