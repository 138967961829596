<template>
  <div>
    <div
      class="alert alert-success alert-dismissible"
      :key="s"
      v-for="s in successes"
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-hidden="true"
      >
        ×
      </button>
      <h5><i class="icon fas fa-check"></i> Erfolg</h5>
      {{ s }}
    </div>

    <div class="alert alert-info alert-dismissible" :key="i" v-for="i in infos">
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-hidden="true"
      >
        ×
      </button>
      <h5><i class="icon fas fa-info"></i> Hinweis</h5>
      {{ i }}
    </div>

    <div
      class="alert alert-danger alert-dismissible"
      :key="e"
      v-for="e in errors"
    >
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-hidden="true"
      >
        ×
      </button>
      <h5><i class="icon fas fa-ban"></i> Fehler</h5>
      {{ e }}
    </div>
  </div>
</template>

<script>
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://cetl.local";

export default {
  name: "Markenwahl",
  props: {
    errors: {
      type: Array,
      required: true,
    },
    infos: {
      type: Array,
      required: true,
    },
    successes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
