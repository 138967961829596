<template>
  <div>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" data-widget="pushmenu" href="#" role="button"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
      </ul>

      <!-- SEARCH FORM -->
      <form class="form-inline ml-3">
        <div class="input-group input-group-sm">
          <input
            class="form-control form-control-navbar"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-append">
            <button class="btn btn-navbar" type="submit">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>

      <ul class="navbar-nav ml-auto">
        <!--<li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-comments"></i>
            <span class="badge badge-danger navbar-badge">3</span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <a href="#" class="dropdown-item">
              <div class="media">
                <div class="media-body">
                  <h3 class="dropdown-item-title">
                    Brad Diesel
                    <span class="float-right text-sm text-danger"
                      ><i class="fas fa-star"></i
                    ></span>
                  </h3>
                  <p class="text-sm">Call me whenever you can...</p>
                  <p class="text-sm text-muted">
                    <i class="far fa-clock mr-1"></i> 4 Hours Ago
                  </p>
                </div>
              </div>
            </a>
            <div class="dropdown-divider"></div>

            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer"
              >See All Messages</a
            >
          </div>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="far fa-bell"></i>
            <span class="badge badge-warning navbar-badge">15</span>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span class="dropdown-item dropdown-header">15 Notifications</span>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">
              <i class="fas fa-envelope mr-2"></i> 4 new messages
              <span class="float-right text-muted text-sm">3 mins</span>
            </a>

            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item dropdown-footer"
              >See All Notifications</a
            >
          </div>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" data-widget="fullscreen" href="#" role="button">
            <i class="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li class="nav-item">
          <span @click="$logout()" class="nav-link logoutButton">
            <i class="fas fa-power-off"></i> Ausloggen
          </span>
        </li>
      </ul>
    </nav>

    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <router-link to="/std/dashboard" class="brand-link">
        <!-- Todo: Je nach Benutzertyp unterschiedliches anzeigen? -->
        <!-- <img src="../../dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8"> -->
              <span class="brand-text font-weight-light">AD2</span>

      </router-link>
 
      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <!-- <img src="../../dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"> -->
          </div>
          <div class="info">
            <router-link to="/settings" class="d-block">{{
              $user.name
            }}</router-link>
          </div>
        </div>

        <!-- SidebarSearch Form -->
        <!--<div class="form-inline">
        <div class="input-group" data-widget="sidebar-search">
          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div> -->

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
          <li class="nav-item">
              <router-link to="/std/dashboard" class="nav-link">
                <i class="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </router-link>
            </li>
           <li class="nav-item">
              <router-link to="/std/dashboard" class="nav-link">
                <i class="nav-icon fas fa-calendar-alt"></i>
                <p>Kalender</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/std/overview" class="nav-link">
                <i class="nav-icon fas fa-clock"></i>
                <p>Besuchsdokumentation</p>
              </router-link>
            </li>

             <li class="nav-item">
              <router-link to="/std/dashboard" class="nav-link">
                <i class="nav-icon fas fa-clipboard-list"></i>
                <p>Umsatzreports</p>
              </router-link>
            </li>

             <li class="nav-item">
              <router-link to="/std/dashboard" class="nav-link">
                <i class="nav-icon fas fa-file-signature"></i>
                <p>Jahresvereinbarungen</p>
              </router-link>
            </li>
             <li class="nav-item">
              <router-link to="/std/dashboard" class="nav-link">
                <i class="nav-icon fas fa-comments"></i>
                <p>Vertriebsfeedback</p>
              </router-link>
            </li>

            <li class="nav-header">Kunden</li>

                        <li class="nav-item">
              <router-link to="/customers" class="nav-link">
                <i class="nav-icon fas fa-wallet"></i>
                <p>Kundenliste</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/customers" class="nav-link">
               <i class="nav-icon fas fa-user"></i>
                <p>Kundenprofil</p>
              </router-link>
            </li>
             <li class="nav-item">
              <router-link to="/std/dashboard" class="nav-link">
                <i class="nav-icon fas fa-plus"></i> 
                <p>Neudepot / Neukunde</p>
              </router-link>
            </li>


            <li class="nav-header">Verwaltung</li>
            <li class="nav-item">
              <router-link to="/settings" class="nav-link">
                <i class="nav-icon fas fa-wrench"></i>
                <p>Meine Einstellungen</p>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/marks" class="nav-link">
                <i class="nav-icon fas fa-project-diagram"></i>
                <p>Marken</p>
              </router-link>
            </li>
            <!-- Temporär deaktiviert (später wieder als Verwaltungspunkt aufnehmen mit Permissioncheck) -->
            <!--
            <li class="nav-item" >
              <router-link to="/customers" class="nav-link">
                <i class="nav-icon fas fa-wallet"></i>
                <p>Kunden</p>
              </router-link>
            </li>-->
            <li
              class="nav-item"
              v-if="hasPermission('user.use') || hasPermission('system.any')"
            >
              <router-link class="nav-link" to="/useradmin">
                <i class="nav-icon fas fa-users"></i>
                <p>Benutzer</p></router-link
              >
            </li>
            <li
              class="nav-item"
              v-if="hasPermission('user.use') || hasPermission('system.any')"
            >
              <router-link class="nav-link" to="/roleadmin">
                <i class="nav-icon fas fa-gavel"></i>
                <p>Rollen &amp; Berechtigungen</p></router-link
              >
            </li>
          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </div>
  <!-- /.navbar -->
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      permissions: ["user.use"],
    };
  },
  methods: {
    hasPermission(perm) {
      return this.permissions.indexOf(perm) > -1;
    },
  },
};
</script>
<style scoped>
.logoutButton:hover {
  color: #dc3545 !important;
}
.brand-link {
  text-align: center;
}


</style>
