<template>
  <Modal
    oktext="Löschen"
    buttonType="danger"
    @confirmed="$emit('confirmed', true)"
    @closed="$emit('closed', true)"
  >
    <template v-slot:title> Datensatz löschen </template>
    <template v-slot:body>
      Soll der Datensatz wirklich gelöscht werden?
    </template>
  </Modal>
</template>
<script>
import Modal from "./Modal.vue";

export default {
  name: "DeleteModal",
  components: {
    Modal,
  },
};
</script>