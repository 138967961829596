<template>
  <BaseTemplate
    title="Besuchsdokumentation"
    :breadCrumbData="[
      { name: 'Dashboard', active: false, to: $defaultDashboard },
      { name: 'Besuchsdokumentation', active: true, to: null },
    ]"
  >
    <template v-slot:body>
      <div class="vapp">
        <Loader v-if="!ajaxHasLoaded" />
        <Card title="Monatsübersicht">
          <template v-slot:tools>
            <button
              class="btn btn-sm btn-success"
              @click="
                $router.push({
                  name: 'stdcreate',
                  params: { cmode: 'create' },
                })
              "
            >
              <i class="fas fa-plus"></i> Neuen Besuch erfassen
            </button>
          </template>
          <template v-slot:body>
            <div v-if="ajaxHasLoaded">
              <Statusbalken
                :errors="errors"
                :infos="infos"
                :successes="successes"
              />

              <template
                v-if="
                  listEntries.length != 0 &&
                  errors.length == 0 &&
                  emptyDataset == false
                "
              >
                <div>
                  <!-- Filter-Datepicker -->
                  <div class="row">
                    <label for="date">Einträge filtern:</label>
                  </div>
                  <date-range-picker
                    opens="opens"
                    :locale-data="locale"
                    v-model="filterDateRange"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :autoApply="true"
                    :ranges="false"
                    :showDropdowns="false"
                    :date-format="dateFormat"
                    minDate="2018-01-01 04:00:00"
                    maxDate="2050-12-26 14:00:00"
                  >
                    <!-- @update="localEntryData.dateRange" -->
                    <!--  Optional scope for the input displaying the dates -->
                    <div slot="input" slot-scope="picker">
                      <div class="row">
                        <div class="col-6">
                          von
                          <input
                            type="text"
                            class="form-control"
                            name="date"
                            :value="picker.startDate | pickerdate"
                          />
                        </div>
                        <div class="col-6">
                          bis
                          <input
                            type="text"
                            class="form-control"
                            name="dateTo"
                            :value="picker.endDate | pickerdate"
                          />
                        </div>
                      </div>
                    </div>
                  </date-range-picker>
                </div>
                <div style="height: 40px"></div>
                <div class="row rowInput">
                  <div class="col-1">
                    <button
                      @click="useFilter"
                      class="btn btn-sm btn-primary"
                      style="width: 100%"
                    >
                      Filtern
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      @click="resetFilter"
                      class="btn btn-sm btn-primary"
                      style="margin-left: 1vw; width: 100%"
                    >
                      Filter zurücksetzen
                    </button>
                  </div>
                  <div class="col-9">&nbsp;</div>
                </div>
                <hr />
              </template>

              <div
                v-if="
                  listEntries.length == 0 &&
                  errors.length == 0 &&
                  emptyDataset == false
                "
              >
                Lade Einträge vom Server..
              </div>
              <div class="w3-row" v-if="emptyDataset == true">
                Keine Einträge vorhanden!
              </div>

              <div v-if="listEntries.length > 0">
                <table class="table">
                  <thead v-if="filteredCount > 0 || filteredCount == -1">
                    <tr>
                      <th>Tag</th>
                      <th>Datum</th>
                      <th>Tätigkeit</th>
                      <th>Kundenname</th>
                      <th>Wichtiger Kommentar</th>
                      <!--<th>Status</th>-->
                      <th>Auslastung</th>
                    </tr>
                  </thead>
                  <div v-if="filteredCount == 0">Keine Einträge gefunden</div>
                  <tbody>
                    <template v-for="n in listEntries">
                      <template v-for="i in n.data">
                        <tr
                          :key="i.id"
                          @click="
                            $router.push({
                              name: 'stdview',
                              params: { cmode: 'edit', id: i.id },
                            })
                          "
                          v-if="i.id > 0 && n.isFiltered == false"
                        >
                          <td style="border-right: 1px solid black">
                            <span>
                              <!-- <a>{{i.id}}</a>  -->
                              {{
                                i.range_from | dayOfWeek | dayOfWeekShorthand
                              }}
                            </span>
                          </td>
                          <td>
                            <span>{{ i.range_from | visibleDate }}</span>
                          </td>
                          <td>
                            <span v-if="i.activity == 'BE'"
                              >Besuch ({{
                                i.visitation | visitationToVisible
                              }})</span
                            >
                            <span v-if="i.activity == 'BT'"
                              >Bürotag ({{
                                i.officeday | officedayToVisible
                              }})</span
                            >
                            <span v-if="i.activity == 'IM'"
                              >Interne Meetings ({{
                                i.intmeeting | intmeetingToVisible
                              }})</span
                            >
                            <span v-if="i.activity == 'AB'"
                              >Abwesend ({{
                                i.absence | absenceToVisible
                              }})</span
                            >
                          </td>
                          <td>
                            <span v-if="i.customer != null">{{
                              i.customer.company_name_short
                            }}</span>
                            <span v-else>&times;</span>
                          </td>
                          <td>
                            <span
                              v-if="i.important_comment != null"
                              class="w3-text-red"
                            >
                              <abbr :title="i.important_comment">Ja</abbr>
                            </span>
                            <span
                              v-if="i.important_comment == null"
                              class="w3-text-green"
                              >Nein</span
                            >
                          </td>
                          <!--<td>
                    <span>
                      {{i.status}}
                      </span>
                  </td>-->
                          <td style="border-left: 1px solid black">
                            <span>
                              {{ i.uload }}
                              <span
                                class="text-warning"
                                v-if="i.status == 'draft'"
                              >
                                <abbr title="Entwurf">(W)</abbr>
                              </span>
                              <span
                                class="text-success"
                                v-if="i.status == 'done'"
                              >
                                <abbr title="Erledigt">(E)</abbr>
                              </span>
                            </span>
                          </td>
                        </tr>
                        <tr
                          :key="i.id"
                          v-if="i.id < 0 && n.isFiltered == false"
                          class="bottomBorder"
                          style="border-top: 1px solid black"
                        >
                          <td class="bottomBorder">
                            <!-- Thread: {{i.id}} -->
                          </td>
                          <td class="bottomBorder">
                            <!-- {{i.range_from | visibleDate }} -->
                          </td>
                          <td class="bottomBorder">
                            <!-- THead: Tätigkeit -->
                          </td>
                          <td class="bottomBorder">
                            <!-- THead: Kundenname -->
                          </td>
                          <td class="bottomBorder">
                            <!-- THead: wichtiger Kommentar -->
                          </td>
                          <td
                            class="bottomBorder"
                            style="
                              background-color: pink;
                              border-left: 1px solid black;
                            "
                            v-if="i.sumLoad != 1"
                          >
                            {{ i.sumLoad }}
                          </td>
                          <td
                            class="bottomBorder"
                            style="
                              background-color: lightgreen;
                              border-left: 1px solid black;
                            "
                            v-if="i.sumLoad == 1"
                          >
                            {{ i.sumLoad }}
                          </td>
                        </tr>
                      </template>
                    </template>
                  </tbody>
                </table>
              </div>
              <hr />
            </div>
          </template>
          <template v-slot:footer>
            <!-- Footer (Datensätze?)-->
          </template>
        </Card>
      </div>
    </template>
  </BaseTemplate>
</template>


<script>
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import Loader from "../micro/Loader.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";
import Statusbalken from "../micro/Statusbalken.vue";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://cetl.local";

export default {
  name: "Uebersicht",
  props: {
    msg: String,
  },
  components: { DateRangePicker, Loader, BaseTemplate, Card, Statusbalken },
  data() {
    return {
      listEntries: [],
      errors: [],
      infos: [],
      successes: [],
      ajaxHasLoaded: false,
      emptyDataset: false,
      idCounter: -1,
      filteredCount: -1, // manually updated by useFilter()-Method
      /* Datepicker Settings */
      opens: "auto", //Ja, dadurch öffnet es sich links..
      startDate: "2019-01-01",
      endDate: "2020-12-31",
      locale: {
        direction: "ltr", //direction of text
        format: "DD.MM.YYYY", //format of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        daysOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        monthNames: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ],
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      /* Ende Datepicker Settings */
      filterDateRange: {
        startDate:
          new Date().getFullYear() +
          "-" +
          (parseFloat(new Date().getUTCMonth()) + 1) +
          "-" +
          new Date().getUTCDate(),
        endDate:
          new Date().getFullYear() +
          "-" +
          (parseFloat(new Date().getUTCMonth()) + 1) +
          "-" +
          new Date().getUTCDate(),
      },
    };
  },
  methods: {
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled =
          date.getTime() < new Date().setDate(new Date().getDate() - 14);
      }
      return classes;
    },

    loadEntriesFromAPI() {
      let cthis = this;
      axios
        .get("/v1/stundenerfassung")
        .then(function (response) {
          if (response.status == 200) {
            if (response.data !== undefined) {
              cthis.listEntries = response.data;

              if (cthis.listEntries.length == 0) {
                cthis.emptyDataset = true;
              }

              // Wir müssen listEntries in Gruppen zerlegen, um auch optisch pro Tag gruppieren zu können..
              let groupedListEntries = []; // TODO: ID & Shizzle

              cthis.listEntries.forEach(function (item) {
                let hasSlot = false;
                groupedListEntries.forEach(function (gpItem) {
                  if (gpItem.id == item.range_from) {
                    item.isFiltered = false;
                    gpItem.data.push(item);
                    hasSlot = true;
                  }
                });
                if (!hasSlot) {
                  groupedListEntries.push({
                    id: item.range_from,
                    data: [item],
                    isFiltered: false,
                  });
                }
              });

              // Sortieren anhand der IDs
              groupedListEntries.sort(function (a, b) {
                return new Date(b.id) - new Date(a.id);
              });
              ///////////////////////
              groupedListEntries.forEach(function (gpSumItem) {
                ////window.console.log(gpSumItem);
                // .id => Datum
                // data push .id + Summe von Loads
                let sum = 0;
                gpSumItem.data.forEach(function (sumItem) {
                  sum += parseFloat(sumItem.uload);
                });
                gpSumItem.data.push({
                  id: --cthis.idCounter,
                  range_from: gpSumItem.id,
                  sumLoad: sum,
                  isFiltered: false,
                });
                sum = 0;
              });

              cthis.listEntries = groupedListEntries;

              //window.console.log(cthis.listEntries);
              cthis.ajaxHasLoaded = true;
            }
          }
        })
        .catch(function (error) {
          cthis.addMessage(
            "error",
            "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es in einigen Minuten erneut. (" +
              error +
              ")"
          );
          window.console.log(error);
        });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    useFilter() {
      let cthis = this;
      let start = new Date(this.filterDateRange.startDate);
      let end = new Date(this.filterDateRange.endDate);
      cthis.filteredCount = 0;

      this.listEntries.forEach(function (item) {
        if (new Date(item.id) < end && new Date(item.id) > start) {
          // in Suchbereich anzeigen
          item.isFiltered = false;
          cthis.filteredCount++;
          window.console.log(cthis.filteredCount);
        } else {
          item.isFiltered = true;
        }
      });
    },
    resetFilter() {
      this.filteredCount = -1;
      this.listEntries.forEach(function (item) {
        item.isFiltered = false;
      });
    },
  },
  filters: {
    visibleDate: function (d) {
      let dat = d.split("-");
      return dat[2] + "." + dat[1] + "." + dat[0];
    },
    dayOfWeek: function (d) {
      return new Date(d).getDay();
    },
    dayOfWeekShorthand: function (d) {
      return ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"][d];
    },
    absenceToVisible(a) {
      switch (a) {
        case "ABUB":
          return "Urlaub";
        case "ABKK":
          return "Krank";
        case "ABFT":
          return "Feiertag";
        case "ABAT":
          return "Ausgleichstag";
        case "ABSG":
          return "Sonstiges";
      }
    },
    intmeetingToVisible(im) {
      switch (im) {
        case "IMEV":
          return "EVK";
        case "IMVM":
          return "Vertriebsmeeting";
        case "IMPG":
          return "Personalgespräch";
        case "IMMS":
          return "Messen";
        case "IMSG":
          return "Sonstiges";
      }
    },
    officedayToVisible(od) {
      switch (od) {
        case "BTK":
          return "Telefonkontakt";
        case "BAD":
          return "Administration";
        case "BWT":
          return "Werkstatt";
        case "BSG":
          return "Sonstiges";
      }
    },
    visitationToVisible(vs) {
      switch (vs) {
        case "VEVK":
          return "EVK";
        case "VPOS":
          return "POS Check";
        case "VFB":
          return "Filialbesuch";
        case "VBB":
          return "Besuch";
        case "VJG":
          return "Jahresgespräch";
        case "VNP":
          return "Neudepot";
        case "VPS":
          return "Produktschulung";
        case "VVK":
          return "Verkaufseinsatz";
        case "VNK":
          return "Neukundenkontakt";
        case "VSG":
          return "Sonstiges";
      }
    },
    pickerdate: function (d) {
      let dat = new Date(d);
      return (
        dat.getUTCDate() +
        "." +
        (parseFloat(dat.getUTCMonth()) + 1) +
        "." +
        dat.getFullYear()
      );
    },
  },
  mounted: function () {
    axios.get("/sanctum/csrf-cookie").then((response) => {
      window.console.log("mounted - uebersicht" + response);

      this.$nextTick(function () {
        let cthis = this;
        axios
          .get("/v1/user")
          .then(function () {
            //window.console.log("mounted - uebersicht - loaddata " + userdata);
            cthis.loadEntriesFromAPI();
          })
          .catch((err) => {
            window.console.log(err);
          });
      });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tbody tr:hover {
  background-color: darkgrey;
}
/* Dem ersten td der ersten tr ein border-top geben */
tr:first-child td {
  border-top: 1px solid black;
}
.bottomBorder {
  border-bottom: 1px solid black;
}
</style>

<style>
.daterangepicker {
  top: 42px !important;
}
</style>