<template>
  <span>
    <div class="row">
      <div class="col-12">
        <label for="customer"
          >Kunde
          <InfoBubble infoText="Bitte wählen Sie den besuchten Kunden aus." />
        </label>
      </div>
    </div>
    <!-- Damit die Rows gleich hoch sind -->
    <div class="row rowInput">
      <div class="col-lg-10 col-sm-6">
        <input
          class="form-control"
          name="customer"
          readonly
          :disabled="status == 'done'"
          :value="visibleSelectedCustomer"
        />
      </div>
      <div class="col-lg-2 col-sm-6">
        <button
          class="btn btn-primary form-control"
          :disabled="status == 'done'"
          @click="showSelectCustomerDialog"
        >
          Kunde auswählen
        </button>
        <!-- Klickbarkeit durch Funktion gesteuert (ob draft|done) -->
      </div>
    </div>

    <div
      class="modal fade show"
      id="modal-xl"
      style="padding-right: 17px; display: block"
      aria-modal="true"
      v-show="modalVisible"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Kunde suchen</h4>
            <button
              type="button"
              class="close"
              @click="hideSelectCustomerDialog"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="modal-content"
              style="
                width: 100%;
                padding: 5px;
                margin-top: 10px;
                box-shadow: none;
                border: none;
              "
            >
              <div class="row">
                <div class="col-4">
                  <label for="companyname">Firmenname Kurz</label>
                </div>
                <div class="col-4">
                  <label for="companyname">Firmenname Lang</label>
                </div>
                <div class="col-4">
                  <label for="group">Gruppe {{ searchGroup }}</label>
                </div>
              </div>

              <div class="row rowInput">
                <div class="col-4">
                  <input
                    class="form-control"
                    type="text"
                    name="companyname"
                    v-model="searchCompanyShort"
                  />
                </div>
                <div class="col-4">
                  <input
                    class="form-control"
                    type="text"
                    name="companyname"
                    v-model="searchCompanyFull"
                  />
                </div>
                <div class="col-4">
                  <select
                    v-model="searchGroup"
                    name="group"
                    class="form-control"
                  >
                    <option></option>
                    <option
                      v-for="group in groups"
                      :key="group.id"
                      :value="group.name"
                    >
                      {{ group.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-6"><label for="store">Kundennummer</label></div>
                <div class="col-6">
                  <label for="street">Straße und Hausnummer</label>
                </div>
              </div>

              <div class="row rowInput">
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="store"
                    v-model="searchCustomerNumber"
                  />
                </div>
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="street"
                    v-model="searchStreet"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6"><label for="zip">Postleitzahl</label></div>
                <div class="col-6"><label for="town">Ort</label></div>
              </div>
              <div class="row rowInput">
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="zip"
                    v-model="searchZip"
                  />
                </div>
                <div class="col-6">
                  <input
                    class="form-control"
                    type="text"
                    name="town"
                    v-model="searchTown"
                  />
                </div>
              </div>
              <div style="height: 2vh"></div>

              <table class="table">
                <thead>
                  <th>Kurzbezeichnung</th>
                  <th>Firmenname</th>
                  <th>Kundennummer</th>
                  <th>Straße</th>
                  <th>Postleitzahl</th>
                  <th>Ort</th>
                  <th>Gruppe</th>
                </thead>
                <tbody>
                  <tr
                    v-for="n in filteredList"
                    :key="n.id"
                    @click="selectCustomer(n)"
                  >
                    <td>{{ n.company_name_short }}</td>
                    <td>{{ n.company_name_full }}</td>
                    <td>{{ n.customer_number }}</td>
                    <td>{{ n.street }}</td>
                    <td>{{ n.zip }}</td>
                    <td>{{ n.town }}</td>
                    <td>
                      <span v-if="n.group != null">{{ n.group.name }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--
          <div class="modal-footer justify-content-between">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>-->
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <div id="customerSelectionDialog" v-show="modalVisible" class="modal">
      <!-- Todo? @click="hideSelectCustomerDialog" -->
      <div class="modal-content">
        <span @click="hideSelectCustomerDialog" class="close">&times;</span>
        <div class="w3-container">
          <h2 style="margin-top: -35px">Kunde Suchen</h2>
          <!-- Minus Margin? Um das Fenster etwas zu stauchen -->
          <div class="w3-row">
            <div class="w3-row">
              <p class="w3-third">
                <label for="companyname">Firmenname Kurz</label>
                <input
                  class="form-control"
                  type="text"
                  name="companyname"
                  v-model="searchCompanyShort"
                />
              </p>
              <p class="w3-third" style="width: 32.4%; margin-left: 0.9%">
                <label for="companyname">Firmenname Lang</label>
                <input
                  class="form-control"
                  type="text"
                  name="companyname"
                  v-model="searchCompanyFull"
                />
              </p>
              <p class="w3-third" style="width: 32.4%; margin-left: 0.9%">
                <label for="group">Gruppe</label>
                <select v-model="searchGroup" name="group" class="form-control">
                  <option></option>
                  <option
                    v-for="group in groups"
                    :key="group.id"
                    :value="group.name"
                  >
                    {{ group.name }}
                  </option>
                </select>
                <!--
                <input
                  class="form-control"
                  type="text"
                  name="group"
                  v-model="searchGroup"
                /> -->
              </p>
            </div>
            <div class="w3-row">
              <p class="w3-half">
                <label for="store">Kundennummer</label>
                <input
                  class="form-control"
                  type="text"
                  name="store"
                  v-model="searchCustomerNumber"
                />
              </p>

              <p class="w3-half" style="width: 49%; margin-left: 0.9%">
                <label for="street">Straße und Hausnummer</label>
                <input
                  class="form-control"
                  type="text"
                  name="street"
                  v-model="searchStreet"
                />
              </p>
            </div>
            <div class="w3-row">
              <p class="w3-half">
                <label for="zip">Postleitzahl</label>
                <input
                  class="form-control"
                  type="text"
                  name="zip"
                  v-model="searchZip"
                />
              </p>
              <p class="w3-half" style="width: 49%; margin-left: 0.9%">
                <label for="town">Ort</label>
                <input
                  class="form-control"
                  type="text"
                  name="town"
                  v-model="searchTown"
                />
              </p>
            </div>
            <div class="w3-row"></div>
          </div>

          <table class="table">
            <thead>
              <th>Kurzbezeichnung</th>
              <th>Firmenname</th>
              <th>Kundennummer</th>
              <th>Straße</th>
              <th>Postleitzahl</th>
              <th>Ort</th>
              <th>Gruppe</th>
            </thead>
            <tbody>
              <!-- Was: filteredList -->
              <tr
                v-for="n in filteredList"
                :key="n.id"
                @click="selectCustomer(n)"
              >
                <td>{{ n.company_name_short }}</td>
                <td>{{ n.company_name_full }}</td>
                <td>{{ n.customer_number }}</td>
                <td>{{ n.street }}</td>
                <td>{{ n.zip }}</td>
                <td>{{ n.town }}</td>
                <td>
                  <span v-if="n.group != null">{{ n.group.name }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import axios from "axios";
import InfoBubble from "../micro/InfoBubble.vue";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://cetl.local";

export default {
  name: "Kundenwahl",
  components: { InfoBubble },
  props: {
    selectedCustomer: {
      required: true,
    },
    status: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      currentSelectedCustomer: {
        company_name_short: "",
        street: "",
        zip: "",
        town: "",
      },
      modalVisible: false,
      readonly: this.status == "done" ? true : false,
      groups: [],
      customerList: [
        /*
        {
          id: 1,
          name: "Peter Lustig GmbH",
          store: "Filiale 123",
          street: "Hauptstraße 23",
          zip: "44141",
          place: "Dortmund"
        }*/
      ],
      searchStreet: null,
      searchCustomerNumber: null,
      searchZip: null,
      searchTown: null,
      searchGroup: null,
      searchCompanyFull: null,
      searchCompanyShort: null,
    };
  },
  computed: {
    visibleSelectedCustomer: function () {
      if (
        this.currentSelectedCustomer.company_name_short == "" ||
        this.currentSelectedCustomer.zip == "" ||
        this.currentSelectedCustomer.town == "" ||
        this.currentSelectedCustomer.street == ""
      ) {
        return "";
      } else {
        return (
          this.currentSelectedCustomer.company_name_short +
          " (" +
          this.currentSelectedCustomer.street +
          ", " +
          this.currentSelectedCustomer.zip +
          " " +
          this.currentSelectedCustomer.town +
          ")"
        );
      }
    },
    filteredList: function () {
      let temp = [];
      let searchables = [];
      {
        let hasContentCount = 0;
        if (this.hasContent(this.searchCompanyFull)) {
          searchables.push({
            needle: this.searchCompanyFull,
            haystack: "company_name_full",
          });
          hasContentCount++;
        }
        if (this.hasContent(this.searchCompanyShort)) {
          searchables.push({
            needle: this.searchCompanyShort,
            haystack: "company_name_short",
          });
          hasContentCount++;
        }
        if (this.hasContent(this.searchStreet)) {
          searchables.push({ needle: this.searchStreet, haystack: "street" });
          hasContentCount++;
        }
        if (this.hasContent(this.searchZip)) {
          searchables.push({ needle: this.searchZip, haystack: "zip" });
          hasContentCount++;
        }
        if (this.hasContent(this.searchGroup)) {
          //window.console.log("blub");
          //window.console.log(this.searchGroup);
          searchables.push({ needle: this.searchGroup, haystack: "group" });
          hasContentCount++;
        }
        if (this.hasContent(this.searchTown)) {
          searchables.push({ needle: this.searchTown, haystack: "town" });
          hasContentCount++;
        }
        if (this.hasContent(this.searchCustomerNumber)) {
          searchables.push({
            needle: this.searchCustomerNumber,
            haystack: "customer_number",
          });
          hasContentCount++;
        } // WAS: STORE searchCustomerNumber
        if (hasContentCount == 0) {
          return this.customerList;
        }
      }
      //let cthis = this;
      this.customerList.forEach(function (item) {
        let toAdd = true;

        searchables.forEach(function (searchItem) {
          // window.console.log("kek");
          // window.console.log(searchItem);
          if (item[searchItem.haystack] != null) {
            // Group.Name remappen

            if (item[searchItem.haystack].name != undefined) {
              if (
                item[searchItem.haystack].name.indexOf(searchItem.needle) == -1
              ) {
                toAdd = false;
              }
            } else if (
              item[searchItem.haystack]
                .toLowerCase()
                .indexOf(searchItem.needle.toLowerCase()) == -1
            ) {
              toAdd = false;
            }
          } else {
            toAdd = false;
          }
        });
        /*
        if (
          cthis.searchCompanyFull !== null &&
          cthis.searchCompanyFull.length > 0
        ) {
          if (
            item.company_name_full
              .toLowerCase()
              .indexOf(cthis.searchCompanyFull.toLowerCase()) >= 0
          ) {
            toAdd = true;
          }
        }

        if (
          cthis.searchCompanyShort !== null &&
          cthis.searchCompanyShort.length > 0
        ) {
          if (
            item.company_name_short
              .toLowerCase()
              .indexOf(cthis.searchCompanyShort.toLowerCase()) >= 0
          ) {
            toAdd = true;
          }
        }
        if (cthis.searchStreet !== null && cthis.searchStreet.length > 0) {
          if (
            item.street
              .toLowerCase()
              .indexOf(cthis.searchStreet.toLowerCase()) >= 0
          ) {
            toAdd = true;
          }
        }
        if (cthis.searchZip !== null && cthis.searchZip.length > 0) {
          if (
            item.zip.toLowerCase().indexOf(cthis.searchZip.toLowerCase()) >= 0
          ) {
            toAdd = true;
          }
        }
        if (cthis.searchTown !== null && cthis.searchTown.length > 0) {
          if (
            item.town.toLowerCase().indexOf(cthis.searchTown.toLowerCase()) >= 0
          ) {
            toAdd = true;
          }
        }

        if (
          cthis.searchCustomerNumber !== null &&
          cthis.searchCustomerNumber.length > 0
        ) {
          if (
            item.customer_number
              .toLowerCase()
              .indexOf(cthis.searchCustomerNumber.toLowerCase()) >= 0
          ) {
            toAdd = true;
          }
        }

        if (
          cthis.searchGroup !== null &&
          cthis.searchGroup.length > 0 &&
          item.group != null
        ) {
          if (
            item.group.name // kein lowerCase, weil Fixe Werte (via Select)
              .indexOf(cthis.searchGroup) >= 0
          ) {
            toAdd = true;
          }
        }*/
        if (toAdd) {
          temp.push(item);
        }
      });

      return temp;
    },
  },
  methods: {
    hasContent: function (data) {
      if (data !== null) {
        if (data.length > 0) {
          return true;
        }
      }
      return false;
    },
    showSelectCustomerDialog: function () {
      //document.getElementById("customerSelectionDialog").style.display = "block";
      if (!this.readonly) this.modalVisible = true;
    },
    hideSelectCustomerDialog: function () {
      this.modalVisible = false;
    },
    selectCustomer: function (customer) {
      this.hideSelectCustomerDialog();
      this.currentSelectedCustomer = customer;
      this.$emit("input", customer.id);
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      // onload

      let cthis = this;
      axios
        .get("/v1/customers/list")
        .then(function (data) {
          //window.console.log("mounted - uebersicht - loaddata " + userdata);
          cthis.customerList = data.data.customers;
          cthis.groups = data.data.groups;

          cthis.customerList.forEach(function (item) {
            if (item.id == cthis.selectedCustomer) {
              cthis.currentSelectedCustomer = item;
            }
          });
        })
        .catch((err) => {
          window.console.log(err);
        });
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* The Modal (background) */
.modal {
  /*display: none;*/ /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: red;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* P-Abstand fürs Modal reduzieren */

.modal-content p {
  margin-top: 5px;
  margin-bottom: 5px;
}
/* Hover Effekt für Tabellenzeilen */

tbody tr:hover {
  background-color: darkgrey;
}
thead th {
  border-bottom: 1px solid gray;
}
.spaceHelper {
  margin-bottom: 0.9vh;
}
.rowInput {
  margin-bottom: 1vh;
}
@media screen and (max-width: 576px) {
  /* --breakpoint-sm */
  button {
    margin-top: 15px;
  }
}
.modal-xl {
  max-width: 1800px;
}
.modal-content {
  width: 95%;
}
.modal-dialog {
  margin-left: 15%;
}
</style>
