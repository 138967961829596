<template>
  <BaseTemplate
    :breadCrumbData="[
      { name: 'Dashboard', active: false, to: $defaultDashboard },
      { name: 'Rollen und Berechtigungen', active: false, to: '/roleadmin' },
      { name: breadCrumbActiveString, active: true, to: null },
    ]"
  >
    <template v-slot:title>
      <span v-if="mode == 'add'">Rolle hinzufügen</span>
      <span v-if="mode == 'edit'">Rolle bearbeiten</span>
    </template>
    <template v-slot:body>
      <Card>
        <template v-slot:title>
          <span v-if="mode == 'add'">Rolle hinzufügen</span>
          <span v-if="mode == 'edit'">Rolle bearbeiten</span>
        </template>
        <template v-slot:body>
          <Loader v-if="!ajaxHasLoaded" />
          <Statusbalken
            :errors="errors"
            :infos="infos"
            :successes="successes"
          />
          <div v-if="ajaxHasLoaded">
            <div class="row">
              <div class="col-12"><label for="rolename">Rollenname</label></div>
            </div>
            <div class="row">
              <div class="col-12">
                <input
                  class="form-control"
                  type="text"
                  name="rolename"
                  v-model="rolename"
                />
              </div>
            </div>
            <div style="height: 20px"></div>

            <table class="table">
              <thead>
                <tr>
                  <th style="text-align: center">Aktiv</th>
                  <th>Berechtigungsname</th>
                  <th>Beschreibung</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in allpermissions" :key="p.name">
                  <td style="text-align: center">
                    <!-- v-model="permCheckbox[p.id]" -->
                    <input
                      type="checkbox"
                      class="permissionCheckbox"
                      :checked="allPermissionsContainId(p.id)"
                      @change="managePermissionCheckbox($event, p)"
                    />
                    <!-- :checked="p.id in permissions"-->
                  </td>
                  <td>{{ p.name }}</td>
                  <td>{{ p.description }}</td>
                </tr>
              </tbody>
            </table>

            <button
              v-if="mode == 'add'"
              class="btn btn-success"
              @click="addRole"
            >
              Rolle hinzufügen
            </button>
            <button
              v-if="mode == 'edit'"
              class="btn btn-primary"
              @click="updateRole"
            >
              Rolle speichern
            </button>

            <!-- Ende Modal für das Hinzufügen neuer Benutzer -->
          </div>
        </template>
      </Card>
    </template>
  </BaseTemplate>
</template>

<script>
import Statusbalken from "../micro/Statusbalken.vue";
import Loader from "../micro/Loader.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";

export default {
  name: "Rollenverwaltung",
  props: {},
  components: { Statusbalken, Loader, BaseTemplate, Card },
  data() {
    return {
      mode: "add",
      roleid: "",
      password: "",
      passwordagain: "",
      email: "",
      rolename: "",
      role: "",
      listEntries: [], // [{ id: 1, name: "foo", email: "bar", role: "admin" }],
      errors: [],
      infos: [],
      successes: [],
      modalMessages: [],
      allpermissions: [],
      permissions: [],
      permCheckbox: [],
      breadCrumbActiveString: "Rolle hinzufügen",
      ajaxHasLoaded: false,
    };
  },
  watch: {
    mode: function () {
      if (this.mode == "edit") {
        this.breadCrumbActiveString = "Rolle bearbeiten";
      } else {
        this.breadCrumbActiveString = "Rolle hinzufügen";
      }
    },
  },
  methods: {
    managePermissionCheckbox: function (value, permObj) {
      value = value.target.checked;

      if (value === true && this.permissions.indexOf(permObj) == -1) {
        this.permissions.push(permObj);
      }
      if (value === false) {
        if (this.permissions.indexOf(permObj) >= -1) {
          let pos = this.permissions.indexOf(permObj);
          this.permissions.splice(pos, 1);
        }
      }
    },
    allPermissionsContainId: function (id) {
      let found = false;
      this.permissions.forEach(function (perm) {
        if (perm.id === id) found = true;
      });
      return found;
    },
    addRole: function () {
      this.validateAddRole();
      if (this.modalMessages.length > 0) return;
      this.clearMessages();
      let onlyPermissionIDs = [];
      this.permissions.forEach(function (data) {
        onlyPermissionIDs.push(data.id);
      });

      let cthis = this;

      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        // window.console.log(response);
        cthis.$axios
          .post("/v1/roleadministration", {
            name: cthis.rolename,
            permissions: onlyPermissionIDs,
          })
          .then(function () {
            cthis.addMessage("success", "Rolle wurde hinzugefügt");
          })
          .catch(function (resp) {
            cthis.addMessage(
              "error",
              "Fehler beim Hinzufügen der Rolle (" +
                resp.response.data.error +
                ")"
            );
          })
          .finally(function () {
            cthis.fetchrolelist();
          });
      });
    },
    updateRole: function () {
      this.validateUpdateRole();
      // window.console.log(this.modalMessages);
      if (this.modalMessages.length > 0) return;
      let onlyPermissionIDs = [];
      this.permissions.forEach(function (data) {
        onlyPermissionIDs.push(data.id);
      });

      let cthis = this;
      this.clearMessages();
      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        // window.console.log(response);
        cthis.$axios
          .put("/v1/roleadministration/" + cthis.$route.params.id, {
            name: cthis.rolename,
            //permissions: cthis.permissions,
            permissions: onlyPermissionIDs,
            role_id: cthis.role,
          })
          .then(function () {
            cthis.addMessage("success", "Änderungen wurden gespeichert.");
          })
          .catch(function (resp) {
            cthis.addMessage(
              "error",
              "Beim Speichern der Änderungen ist ein Fehler aufgetreten (" +
                resp.response.data.error +
                ")"
            );
          })
          .finally(function () {
            cthis.fetchrolelist();
          });
      });
    },
    fetchrolelist() {
      let cthis = this;

      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        cthis.$axios
          .get("/v1/permissionadministration")
          .then((sentdata) => {
            // Onload: Einträge laden
            cthis.allpermissions = sentdata.data;
            cthis.ajaxHasLoaded = true;
          })
          .catch(function () {
            cthis.addMessage("error", "Fehler beim Laden der Rollen");
            return;
          })
          .finally(function () {});
      });
    },
    loadRole() {
      let cthis = this;
      this.$axios.get("/sanctum/csrf-cookie").then(function () {
        cthis.$axios
          .get("/v1/roleadministration/" + cthis.$route.params.id)
          .then((sentdata) => {
            cthis.roleid = sentdata.data.id;
            cthis.rolename = sentdata.data.name;
            cthis.permissions = sentdata.data.permissions;

            cthis.ajaxHasLoaded = true;
          })
          .catch(function () {
            cthis.addMessage("error", "Fehler beim Laden der Rollenliste");
            return;
          })
          .finally(function () {});
      });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    clearMessages() {
      this.infos = [];
      this.errors = [];
      this.successes = [];
    },
    validateDeleteRole() {
      /* Parameter was: uid */
      // Todo: Berechtigungscheck oder dergleichen?
    },
    validateUpdateRole() {
      this.modalMessages = []; // reset vor Validierung
      this.validationHelper();
    },
    validateAddRole() {
      this.modalMessages = [];
      this.validationHelper();
    },
    validationHelper() {
      if (this.rolename == "") {
        this.addModalMessage("Das Feld Rollenname ist ein Pflichtfeld");
      }
    },
  },
  mounted: function () {
    let cthis = this;
    this.$nextTick(function () {
      this.fetchrolelist();
      if (cthis.$route.params.id != undefined) {
        cthis.mode = "edit";
        cthis.loadRole();
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tbody tr:hover {
  background-color: rgb(189, 187, 187);
}
td {
  vertical-align: middle;
}
</style>
