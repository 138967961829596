<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <a href="/login" class="h1"><b>AD</b>2</a>
        </div>
        <div class="card-body">
          <p class="login-box-msg">Bitte einloggen, um fortzufahren</p>
          <!--
<div
          class="w3-panel w3-pale-green w3-border statebox"
          :key="s"
          v-for="s in successes"
          v-html="s"
        ></div>
        <div
          style="display:fixed;"
          class="w3-panel w3-pale-red w3-border statebox"
          :key="e"
          v-for="e in errors"
          v-html="e"
        ></div> -->
          <Statusbalken
            :errors="errors"
            :infos="infos"
            :successes="successes"
          />
          <div>
            <div class="input-group mb-3">
              <input
                class="form-control"
                type="email"
                placeholder="Email-Adresse"
                name="user"
                v-model="user"
                required
                autofocus
                tabindex="1"
                v-on:keydown.enter="$refs.password.focus()"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                ref="password"
                class="form-control"
                type="password"
                placeholder="Passwort"
                name="password"
                v-model="password"
                required
                v-on:keydown.enter="login()"
                tabindex="2"
              />

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                  @click="login()"
                  tabindex="3"
                >
                  Einloggen
                  <div v-if="showSpinner" class="lds-ellipsis">
                    <div></div>
                    <!-- Für die Ladeanimation - nicht entfernen -->
                    <div></div>
                    <!-- Für die Ladeanimation - nicht entfernen -->
                    <div></div>
                    <!-- Für die Ladeanimation - nicht entfernen -->
                    <div></div>
                    <!-- Für die Ladeanimation - nicht entfernen -->
                  </div>
                </button>
              </div>
              <!-- /.col -->
            </div>
          </div>

          <!--
      <p class="mb-1">
        <a href="forgot-password.html">Passwort vergessen</a>
      </p>
-->
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
    <!-- /.login-box -->
  </div>
</template>

<script>
import Statusbalken from "../micro/Statusbalken.vue";
export default {
  name: "LoginSeite",
  data() {
    return {
      user: "",
      password: "",
      errors: [],
      successes: [],
      infos: [],
      showSpinner: false,
    };
  },
  components: { Statusbalken },
  methods: {
    login() {
      let cthis = this;
      cthis.showSpinner = true;
      this.errors = [];
      if (this.user == "" || this.password == "") {
        this.addMessage(
          "error",
          "Bitte geben Sie einen Benutzernamen und ein Passwort ein!"
        );
        cthis.showSpinner = false;
        return;
      }
      this.$axios
        .get("/sanctum/csrf-cookie")
        .then((response) => {
          window.console.log(response);
          cthis.$axios
            .post("/v1/login", {
              email: this.user,
              password: this.password,
            })
            .then(function () {
              // Onload: Einträge laden
              cthis.$axios.get("/v1/user").then((userdata) => {
                window.console.log(userdata);
                cthis.addMessage("success", "Login erfolgreich!");

                cthis.$login(userdata.data);

                cthis.$router.push({ path: "/std/dashboard" });
                //cthis.$emit("success", userdata);
              });
            })
            .catch(function () {
              cthis.addMessage("error", "Fehlerhafte Logindaten.");
            })
            .finally(function () {
              cthis.showSpinner = false;
            });
        })
        .catch(function () {
          cthis.addMessage(
            "error",
            "Server antwortet nicht. Bitte versuchen Sie es später erneut."
          );
        })
        .finally(function () {
          cthis.showSpinner = false;
        });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
  },
};
</script>

<style scoped>
#centerize {
  margin-top: 20%;
}
/* Bordered form */
form {
  border: 3px solid #f1f1f1;
}

/* Full-width inputs */
/*input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
} */

input:focus {
  border-color: green;
}

/* Set a style for all buttons */
button {
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
}

/* Add a hover effect for buttons */
button:hover {
  opacity: 0.8;
}

/* Extra style for the cancel button (red) */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

/* Center the avatar image inside this container */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

/* Avatar image */
img.avatar {
  width: 40%;
  border-radius: 50%;
}

/* Add padding to containers */
.container {
  padding: 16px;
}

/* The "Forgot password" text */
span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  .cancelbtn {
    width: 100%;
  }
}

/* CSS Ladeanimation */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}
.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
