import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

Vue.config.productionTip = false

//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import Uebersicht from "./components/std/Uebersicht.vue";
import Benutzereinstellungen from "./components/global/Benutzereinstellungen.vue";
import Benutzerverwaltung from "./components/admin/Benutzerverwaltung.vue";
import BenutzerSC from "./components/admin/BenutzerSC.vue";
import Rollenverwaltung from "./components/admin/Rollenverwaltung.vue";
import RolleSC from "./components/admin/RolleSC.vue";
import Stundenerfassung from "./components/std/Stundenerfassung.vue";
import Markenverwaltung from "./components/coredata/Markenverwaltung.vue";
import Kundenverwaltung from "./components/coredata/Kundenverwaltung.vue";
import LoginSeite from "./components/global/Login.vue";
import StdDashboardSeite from "./components/std/Dashboard.vue";



import axios from "axios";

Vue.prototype.$project = "AD2 29/01/2021";

//Vue.prototype.$frontEndURL = "https://app.cetl.local";
//Vue.prototype.$backEndURL = "https://cetl.local";
Vue.prototype.$frontEndURL = "https://app.ad2.weloveperfume.de";
Vue.prototype.$backEndURL = "https://ad2.weloveperfume.de";

axios.defaults.baseURL = Vue.prototype.$backEndURL;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 5000; // 5 Sekunden
Vue.prototype.$axios = axios;

Vue.prototype.$logout = function() {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("user");
    // maybe: localStorage.clear()?
    Vue.prototype.$axios.get('/v1/logout');
    router.push("/login");
}

Vue.prototype.$user = undefined;
Vue.prototype.$login = function(data) {
    Vue.prototype.$user = data;
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("isAuthenticated", true);
}

if (localStorage.user != undefined) {
    Vue.prototype.$login(JSON.parse(localStorage.user));
}

if (localStorage.isAuthenticated != undefined) {
    Vue.prototype.$isAuthenticated = localStorage.isAuthenticated;
}

Vue.prototype.$defaultDashboard = '/std/dashboard'; // Fix, nur zum Testen. 

Vue.use(VueRouter);

const routes = [
    /* Benutzeradministration */
    { path: '/useradmin', component: Benutzerverwaltung, meta: { requiresAuth: true } },
    { path: '/useradmin/page/:pageId', component: Benutzerverwaltung, props: true, name: 'userspage', meta: { requiresAuth: true } },
    { path: '/useradmin/create', component: BenutzerSC, meta: { requiresAuth: true } },
    { path: '/useradmin/:id', component: BenutzerSC, meta: { requiresAuth: true } },
    /* Rollenadministration - Administration */
    { path: '/roleadmin', component: Rollenverwaltung, meta: { requiresAuth: true } },
    { path: '/roleadmin/page/:pageId', component: Rollenverwaltung, props: true, name: 'rolespage', meta: { requiresAuth: true } },
    { path: '/roleadmin/create', component: RolleSC, meta: { requiresAuth: true } },
    { path: '/roleadmin/:id', component: RolleSC, meta: { requiresAuth: true } },
    /* Besuchsdoku-Bezogene Dinge */
    { path: '/std/dashboard', component: StdDashboardSeite, meta: { requiresAuth: true } },
    { path: '/std/overview/page/:pageId', component: Uebersicht, props: true, name: 'stdoverviewpage', meta: { requiresAuth: true } },
    { path: '/std/overview', component: Uebersicht, meta: { requiresAuth: true } },
    { path: '/std/:cmode/:id', component: Stundenerfassung, name: 'stdview', props: true, meta: { requiresAuth: true } },
    { path: '/std/:cmode', component: Stundenerfassung, name: 'stdcreate', props: true, meta: { requiresAuth: true } },
    /* Kunden */
    { path: '/customers', component: Kundenverwaltung, meta: { requiresAuth: true } },
    { path: '/customers/page/:pageId', component: Kundenverwaltung, props: true, name: 'customerspage', meta: { requiresAuth: true } },
    { path: '/customers/create', component: Kundenverwaltung, meta: { requiresAuth: true } },
    { path: '/customers/:id', component: Kundenverwaltung, meta: { requiresAuth: true } },
    /* Marken */
    { path: '/marks', component: Markenverwaltung, meta: { requiresAuth: true } },
    { path: '/marks/page/:pageId', component: Markenverwaltung, props: true, name: 'markspage', meta: { requiresAuth: true } },
    { path: '/marks/create', component: Markenverwaltung, meta: { requiresAuth: true } },
    { path: '/marks/:id', component: Markenverwaltung, meta: { requiresAuth: true } },
    /* Benutzerbezogenes */
    { path: '/settings', component: Benutzereinstellungen, meta: { requiresAuth: true } },
    /* Globale Routen */
    { path: '/login', component: LoginSeite, meta: { requiresAuth: false } },
    { path: '*', redirect: '/login', meta: { requiresAuth: false } }
]
const router = new VueRouter({
    mode: 'history',
    routes: routes
})





router.beforeEach(function(to, from, next) {
    //    window.scrollTo(0, 0);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        axios
            .get("/v1/user")
            .then(userdata => {
                if (userdata instanceof Object) {
                    next();
                }
            })
            .catch(function() {
                next({
                    path: '/login'
                })
            });
    } else {
        next();
    }
});

new Vue({
    render: h => h(App),
    router: router
}).$mount('#app')