
<template>
  <body class="hold-transition sidebar-mini">
    <!-- Site wrapper -->
    <div class="wrapper">
      <!-- Navbar -->
      <Sidebar />

      <!-- Main Sidebar Container -->

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row mb-2">
              <div class="col-sm-6">
                <h1>
                  <span v-if="title != undefined">{{ title }}</span>
                  <slot name="title"></slot>
                </h1>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <template v-for="bread in breadCrumbData">
                    <router-link
                      v-if="bread.to != null"
                      :key="bread.name"
                      :to="bread.to"
                      class="breadcrumb-item"
                      :class="{ active: bread.active }"
                      >{{ bread.name }}</router-link
                    >
                    <span v-else :key="bread.name" class="breadcrumb-item">{{
                      bread.name
                    }}</span>
                  </template>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>

        <!-- Main content -->
        <section class="content">
          <!-- Default box -->
          <slot name="body"></slot>
          <!-- /.card -->
        </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->

      <footer class="main-footer">
        <strong><!--Timo Meschkat &copy;--> 2020 - 2021 </strong>
        <span style="float: right">
          {{ $project }}
        </span>
      </footer>

      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
  </body>
</template>
<script>
import Sidebar from "../micro/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
  props: {
    title: {
      required: false,
    },
    breadCrumbData: {
      required: true,
    },
  },
};
</script>