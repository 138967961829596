<template>
  <span>
    <div style="row">
      <div class="col-12" style="padding-left: 0">
        <label
          >Markenauswahl
          <InfoBubble
            infoText="Bitte wählen Sie eine oder mehrere betroffene Marken aus"
        /></label>
      </div>
    </div>
    <div class="row" style="margin-bottom:-5px;"> <!-- Hack, damit der Abstand zwischen den Rows gleich bleibt -->
      <template v-for="(a, index) in marks">
        <div class="col-lg-2 col-sm-2" :key="a.id" v-show="index <= 6 || showAll">
          <!-- had: class="markEntry" -->
          <div
            class="card markEntry"
            :class="{ selected: a.selected, readOnlyEntry: readonly }"
            @click="changeColor(a)"
            :key="a.id"
            v-show="index <= 6 || showAll"
          >
            <div class="card-body">
              <p :class="{ italic: a.selected }" style="margin-bottom: 0">
                <!-- opt. Checkbox: <input type="checkbox" :checked="a.selected" />-->
                {{ a.name }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <div class="col-lg-2 col-sm-2 markEntry">
        <div v-if="marks.length > 0" class="card" @click="showOrHideAllMarks">
          <div class="card-body">
            <p v-if="!showAll" style="margin-bottom: 0">
              <i class="fas fa-eye"></i> Weitere Marken anzeigen..
            </p>
            <p v-if="showAll" style="margin-bottom: 0">
              <i class="fas fa-eye-slash"></i> Marken ausblenden
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="marks.length == 0" class="w3-text-red">
      Es wurden keine Marken im System hinterlegt!
    </div>
  </span>
</template>

<script>
import InfoBubble from "../micro/InfoBubble.vue";

export default {
  name: "Markenwahl",
  components: { InfoBubble },
  props: {
    msg: String,
    test: Array,
    selectedMarks: {
      type: Array,
      required: true,
    },
    status: {
      type: String,
      required: false, // Falls die Markenwahl mal ohne ro-kontext genutzt werden soll (anderer Stelle als Stundenerfassung direkt maybe)
    },
  },
  data() {
    return {
      marks: [],
      selectedMarkEntries: this.selectedMarks,
      showAll: false,
      readonly: this.status == "done" ? true : false,

    };
  },
  methods: {
    changeColor: function (obj) {
      //window.console.log(this.selectedMarks);
      if (this.readonly) return; // RO-Modus

      this.marks.forEach((value) => {
        if (obj.id == value.id) {
          if (obj.selected) {
            obj.selected = false;
          } else {
            obj.selected = true;
          }
        }
      });
      this.$emit("input", this.marks);
      //window.console.log("Triggered Markenwahl");
      //window.console.log(this.marks);
    },
    showOrHideAllMarks: function () {
      this.showAll = !this.showAll;
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      if (this.marks.length == 0) {
        this.$axios.get("/v1/marks/list").then((markdata) => {
          if (markdata.status == 200) {
            //let containsSelected = false;
            markdata.data.forEach((value) => {
              if (value.selected == undefined) {
                value.selected = false; // Wert initialisieren

                this.selectedMarks.forEach((sm) => {
                  if (value.id == sm.id) {
                    value.selected = true;
                    //containsSelected = true;
                  }
                });
              }
            });
            this.marks = markdata.data;
            // Todo: Check, ob das alles klappt!
            window.console.log(this.marks);

            // compMark im ParentObject initialisieren

            this.$emit("input", this.marks);

            window.console.log("Markenauswahl");
            window.console.log(this.selectedMarks);
          } else {
            this.addMessage(
              "error",
              "Fehler beim Laden der Markeninformationen"
            );
          }
        });
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.markEntry:hover {
  cursor: pointer;
}
.readOnlyEntry:hover {
  cursor: not-allowed;
}
.selected {
  background-color: gold;
}
.italic {
  font-style: italic;
  text-decoration: underline;
}
</style>
