<template>
  <span>
    <span class="trigger">
      <!-- &#128712; -->
      <i class="fas fa-info-circle"></i>
      <!-- Info Icon in Unicode -->
    </span>
    <span class="hiddenBubble"
      ><i class="fas fa-info-circle"></i> <span>{{ infoText }}</span></span
    >
  </span>
</template>

<script>
export default {
  name: "InfoBubble",
  props: {
    infoText: {
      type: String, // auf Object umstellen, wenn das BE es kann. TODO!
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hiddenBubble {
  display: none;
}
.trigger:hover + .hiddenBubble {
  display: block;
  position: absolute;
  left: 0;
  top: -35px;
  max-width: 80%;
  word-wrap: break;
  font-size: 14px;
  background-color: #fff;
  padding: 1.2vh;
  color: #000;
  border-radius: 3px;
  border: 1px solid black;
  font-weight: normal;
}
</style>
