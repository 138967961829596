<template>
  <BaseTemplate
    title="Rollen- und Berechtigungsverwaltung"
    :breadCrumbData="[
      { name: 'Dashboard', active: false, to: $defaultDashboard },
      { name: 'Rollen und Berechtigungen', active: true, to: null },
    ]"
  >
    <template v-slot:body>
      <Loader v-if="errors" />
      <Card title="Rollen und Berechtigungen">
        <template v-slot:tools>
          <router-link to="/roleadmin/create" class="btn btn-sm btn-success">
            <i class="fas fa-plus"></i> Rolle hinzufügen
          </router-link>
        </template>
        <template v-slot:body>
          <div>
            <Modal
              v-if="modalVisible"
              oktext="Löschen"
              buttonType="danger"
              @confirmed="deleteRoleFromEnv()"
              @closed="modalVisible = false"
            >
              <template v-slot:title> Datensatz löschen </template>
              <template v-slot:body>
                Soll der Datensatz wirklich gelöscht werden?
              </template>
            </Modal>
            <Statusbalken
              :errors="errors"
              :infos="infos"
              :successes="successes"
            />
            <div v-if="listEntries.length > 0">
              <table class="table">
                <thead>
                  <tr>
                    <th>Nr.</th>
                    <th>Rollenname</th>
                    <th>Anzahl Benutzer</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="n in listEntries">
                    <tr :key="n.id">
                      <td>
                        <span>{{ n.id }}</span>
                      </td>
                      <td>
                        <span>{{ n.name }}</span>
                      </td>
                      <td>
                        <span>{{ n.users.length }}</span>
                      </td>

                      <td style="vertical-align: top">
                        <div class="btn-group">
                          <router-link
                            :to="'/roleadmin/' + n.id"
                            class="btn btn-sm btn-primary"
                            ><i class="fas fa-pen"></i
                          ></router-link>
                          <button
                            class="btn btn-sm btn-danger"
                            @click="copyToEnv(n)"
                            :title="'Rolle ' + n.name + ' löschen'"
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>            
          </div>
        </template>
      </Card>
    </template>
  </BaseTemplate>
</template>

<script>
import axios from "axios";

import Statusbalken from "../micro/Statusbalken.vue";
import Loader from "../micro/Loader.vue";
import Modal from "../micro/Modal.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "https://cetl.local";

export default {
  name: "Rollenverwaltung",
  props: {},
  components: { Statusbalken, Loader, BaseTemplate, Card, Modal },
  data() {
    return {
      mode: "add",
      roleid: "",
      password: "",
      passwordagain: "",
      email: "",
      rolename: "",
      role: "",
      roles: [],
      modalRoleVisible: false,
      listEntries: [], // [{ id: 1, name: "foo", email: "bar", role: "admin" }],
      errors: [],
      infos: [],
      successes: [],
      modalMessages: [],
      allpermissions: [],
      permissions: [],
      permCheckbox: [],
      envData: {},
      modalVisible: false,
    };
  },
  methods: {
    copyToEnv: function (payload) {
      this.envData = payload;
      this.modalVisible = true;
    },
/*
    managePermissionCheckbox: function (value, permObj) {
      value = value.target.checked;
      // window.console.log("value");
      // window.console.log(value);
      // window.console.log("permObj");
      // window.console.log(permObj);
      if (value === true && this.permissions.indexOf(permObj) == -1) {
        // window.console.log("push appeared");
        this.permissions.push(permObj);
      }
      if (value === false) {
        if (this.permissions.indexOf(permObj) >= -1) {
          let pos = this.permissions.indexOf(permObj);
          this.permissions.splice(pos, 1);
        }
      }
      // window.console.log(this.permissions);
    },
    allPermissionsContainId: function (id) {
      let found = false;
      this.permissions.forEach(function (perm) {
        if (perm.id === id) found = true;
      });
      return found;
    },
  */ 
    fetchrolelist() {
      let cthis = this;

      axios.get("/sanctum/csrf-cookie").then(function () {
        //window.console.log(response);
        axios
          .get("/v1/roleadministration")
          .then((sentdata) => {
            //window.console.log(sentdata.data);
            // Onload: Einträge laden
            cthis.listEntries = sentdata.data;
          })
          .catch(function () {
            cthis.addMessage("error", "Fehler beim Laden der Rollenliste");
            return;
          })
          .finally(function () {});

        axios
          .get("/v1/permissionadministration")
          .then((sentdata) => {
            // Onload: Einträge laden
            cthis.allpermissions = sentdata.data;
          })
          .catch(function () {
            cthis.addMessage("error", "Fehler beim Laden der Rollen");
            return;
          })
          .finally(function () {});
      });
    },
    
    deleteRoleFromEnv() {
      let obj = this.envData;
      this.validateDeleteRole(obj.id);

      this.clearMessages();
      this.mode = "deletion";
      // Löschen
      let cthis = this;

      axios.get("/sanctum/csrf-cookie").then(function () {
        axios
          .delete("/v1/roleadministration/" + obj.id)
          .then(function () {
            cthis.addMessage("success", "Rolle wurde gelöscht");
          })
          .catch(function (resp) {
            cthis.addMessage(
              "error",
              "Rolle konnte nicht gelöscht werden (" +
                resp.response.data.error +
                ")"
            );
          })
          .finally(function () {
            cthis.fetchrolelist();
            cthis.modalVisible = false;

            cthis.mode = "";
          });
      });
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    clearMessages() {
      this.infos = [];
      this.errors = [];
      this.successes = [];
    },
    addModalMessage(text) {
      if (this.modalMessages.indexOf(text) > -1) return;
      this.modalMessages.push(text);
    },
    validateDeleteRole(uid) {
      window.console.log(uid);
      // Todo: Berechtigungscheck oder dergleichen?
    },

  },
  mounted: function () {
    this.$nextTick(function () {
      this.fetchrolelist();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
tbody tr:hover {
  background-color: rgb(189, 187, 187);
}
td {
  vertical-align: middle;
}
</style>
