<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" style="margin-top: 5px">
        <span v-if="title != undefined">{{ title }}</span
        ><slot name="title"></slot>
      </h3>

      <div class="card-tools">
        <slot name="tools"></slot>
        <!-- <button
          type="button"
          class="btn btn-tool"
          data-card-widget="collapse"
          title="Collapse"
        >
          <i class="fas fa-minus"></i>
        </button>
        <button
          type="button"
          class="btn btn-tool"
          data-card-widget="remove"
          title="Remove"
        >
          <i class="fas fa-times"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body">
      <slot name="body"> </slot>
    </div>
    <!-- /.card-body -->
    <div class="card-footer">
      <slot name="footer"> </slot>
    </div>
    <!-- /.card-footer-->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    title: {
      required: false,
    },
    hasTitleSlot: {
      required: false,
    },
  },
};
</script>