<template>
  <BaseTemplate
    title="Besuchsberichterfassung"
    :breadCrumbData="[
      { name: 'Dashboard', active: false, to: $defaultDashboard },
      { name: 'Besuchsdokumentation', active: false, to: '/std/overview' },
      { name: 'Berichtsansicht', active: true, to: null },
    ]"
  >
    <template v-slot:body>
      <Loader v-if="true" />
      <Card title="Besuchs- oder Tätigkeitsbericht">
        <template v-slot:tools> </template>

        <template v-slot:body>
          <Statusbalken
            :errors="errors"
            :infos="infos"
            :successes="successes"
          />

          <div class="row" v-if="!loaded && errors.length == 0">
            <div class="col-12">Lade Datensatz.....</div>
          </div>
          <template v-if="loaded">
            <div class="row">
              <div class="col-12">
                <label class="label" for="date">
                  Datum
                  <InfoBubble
                    infoText="Wählen Sie bitte den zu dokumentierenden Zeitraum durch einen Klick auf die Eingabefelder und anschließend auf den Kalender aus."
                  />
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="date">Von</label>
              </div>
              <div class="col-6">
                <label for="dateTo">Bis</label>
              </div>
            </div>

            <date-range-picker
              :opens="opens"
              :locale-data="locale"
              v-model="localEntryData.dateRange"
              :singleDatePicker="false"
              :timePicker="false"
              :autoApply="true"
              :ranges="false"
              :showDropdowns="false"
              :date-format="dateFormat"
              minDate="2018-01-01 04:00:00"
              maxDate="2050-12-26 14:00:00"
            >
              <!-- @update="localEntryData.dateRange" -->
              <!--  Optional scope for the input displaying the dates -->
              <div slot="input" slot-scope="picker">
                <div class="row">
                  <div class="col-6">
                    <!-- <label for="date">Von</label> -->
                    <input
                      type="text"
                      class="form-control"
                      name="date"
                      :value="picker.startDate | pickerdate"
                      :disabled="localEntryData.status == 'done'"
                    />
                  </div>
                  <div class="col-6">
                    <!-- <label for="dateTo">Bis</label> -->
                    <input
                      type="text"
                      class="form-control"
                      name="dateTo"
                      :value="picker.endDate | pickerdate"
                      :disabled="localEntryData.status == 'done'"
                    />
                  </div>
                </div>
              </div>
            </date-range-picker>
            <!-- Aktivität = Immer sichtbar -->
            <div style="height: 10px"></div>
            <div class="row">
              <div class="col-12">
                <!-- Position: relative wird für die Infobubble benötigt (damit diese die Blase gescheit positioniert) -->
                <label for="activity">
                  Aktivität
                  <InfoBubble
                    infoText="Wählen Sie bitte den Aktivitätsbereich, dem die Tätigkeit zugeordnet werden kann."
                  />
                </label>
              </div>
            </div>
            <div class="row rowInput">
              <div class="col-12">
                <select
                  class="form-control"
                  name="activity"
                  v-model="localEntryData.activity"
                  :disabled="localEntryData.status == 'done'"
                >
                  {{
                    localEntryData.activity
                  }}
                  <option
                    v-for="act in listOfActivities"
                    :key="act.name"
                    :value="act.id"
                  >
                    {{ act.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Folgeselect für 'Besuch'. Nur sichtbar, wenn Besuch ausgewählt wurde -->
            <div class="row" v-if="localEntryData.activity == 'BE'">
              <div class="col-12">
                <label for="visitation">
                  Tätigkeit vor Ort
                  <InfoBubble
                    infoText="Welche Aktivität haben Sie vor Ort durchgeführt?"
                  />
                </label>
              </div>
            </div>
            <div class="row rowInput" v-if="localEntryData.activity == 'BE'">
              <div class="col-12">
                <select
                  class="form-control"
                  name="visitation"
                  v-model="localEntryData.visitation"
                  :disabled="localEntryData.status == 'done'"
                >
                  <option
                    v-for="vact in listOfVisitationActivities"
                    :key="vact.name"
                    :value="vact.id"
                  >
                    {{ vact.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Folgeselect für 'Besuch'. Nur sichtbar, wenn Besuch ausgewählt wurde -->
            <div
              class="row"
              v-if="
                localEntryData.activity == 'BE' &&
                localEntryData.visitation == 'VPS'
              "
            >
              <div class="col-12">
                <label for="attendees">
                  Anzahl Schulungsteilnehmer
                  <InfoBubble
                    infoText="Wie viele Personen haben an der Schulung teilgenommen?"
                  />
                </label>
              </div>
            </div>
            <div
              class="row rowInput"
              v-if="
                localEntryData.activity == 'BE' &&
                localEntryData.visitation == 'VPS'
              "
            >
              <div class="col-12">
                <input
                  class="form-control"
                  type="text"
                  name="attendees"
                  :disabled="localEntryData.status == 'done'"
                  v-model="localEntryData.attendees"
                />
              </div>
            </div>

            <!-- Bürotag-Aktivitätenauswahl -->
            <div class="row" v-if="localEntryData.activity == 'BT'">
              <div class="col-12">
                <label for="officeday">
                  Bürotätigkeit
                  <InfoBubble
                    infoText="Wählen Sie bitte den Tätigkeit im Büro, die ausgeübt wurde."
                  />
                </label>
              </div>
            </div>
            <div class="row rowInput" v-if="localEntryData.activity == 'BT'">
              <div class="col-12">
                <select
                  class="form-control"
                  name="officeday"
                  v-model="localEntryData.officeday"
                  :disabled="localEntryData.status == 'done'"
                >
                  <option
                    v-for="d in listOfOfficeDayActivities"
                    :key="d.name"
                    :value="d.id"
                  >
                    {{ d.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Hier war ein Spacer -->
            <!-- Kundenauswahl Komponente verwenden -->
            <Kundenwahl
              v-if="
                localEntryData.activity == 'BE' ||
                (localEntryData.activity == 'BT' &&
                  localEntryData.officeday == 'BTK')
              "
              v-model="localEntryData.customer_id"
              :selectedCustomer="localEntryData.customer_id"
              :status="localEntryData.status"
            />
            <!-- selectedCustomer? -->
            <!-- Obj einfügen! TODO^ -->
            <!-- Hier war ein Spacer -->

            <!-- Anzeige des Selects für die Auswahl des betroffenen EVKs (Sichtbar bei: Besuch > EVK und Bürotag > Telefonkontakt ) -->
            <div
              class="row"
              v-if="
                (localEntryData.activity == 'BE' &&
                  localEntryData.visitation == 'VEVK') ||
                (localEntryData.activity == 'BT' &&
                  localEntryData.officeday == 'BTK')
              "
            >
              <div class="col-12">
                <label v-if="localEntryData.activity == 'BE'" for="evk"
                  >EVK-Typ</label
                >
                <label v-if="localEntryData.activity == 'BT'" for="evk"
                  >Gesprächsthema</label
                >
              </div>
            </div>
            <div
              class="row rowInput"
              v-if="
                (localEntryData.activity == 'BE' &&
                  localEntryData.visitation == 'VEVK') ||
                (localEntryData.activity == 'BT' &&
                  localEntryData.officeday == 'BTK')
              "
            >
              <div class="col-12">
                <select
                  class="form-control"
                  name="evk"
                  v-model="localEntryData.evk"
                  :disabled="localEntryData.status == 'done'"
                >
                  <option v-for="e in listOfEVKs" :key="e.name" :value="e.id">
                    {{ e.name }}
                  </option>
                  <!-- Für Telefonkontakt -->
                  <template
                    v-if="
                      localEntryData.activity == 'BT' &&
                      localEntryData.officeday == 'BTK'
                    "
                  >
                    <option value="BTKTM">Terminvereinbarung</option>
                    <option value="BTKSG">Sonstiges</option>
                  </template>
                </select>
              </div>
            </div>
            <!-- Hier war ein Spacer -->
            <!-- Markenauswahl anzeigen (Bei Besuch [außer POS-Check], Telefonkontakt) -->
            <markenwahl
              v-if="showMark"
              v-model="localEntryData.compMarken"
              :selectedMarks="localEntryData.marks"
              :status="localEntryData.status"
            ></markenwahl>
            <!-- ^status => done, dann readonly! -->

            <!-- v-on:markenChange="markenChange" -->
            <!-- POS-Check Typ Select, nur bei POS Check -->
            <div
              class="row"
              v-if="
                localEntryData.activity == 'BE' &&
                localEntryData.visitation == 'VPOS'
              "
            >
              <div class="col-12">
                <label for="postype">POS-Check-Typ</label>
                <select
                  class="form-control"
                  name="postype"
                  v-model="localEntryData.postype"
                  :disabled="localEntryData.status == 'done'"
                >
                  <option value="VPOSILC">Instore Location Check</option>
                  <option value="VPOSSOM">Sell-Out Management</option>
                </select>
              </div>
            </div>
            <!-- Abwesenheit Select -->
            <div class="row" v-if="localEntryData.activity == 'AB'">
              <div class="col-12">
                <label for="absence">
                  Abwesenheitstyp
                  <InfoBubble
                    infoText="Wählen Sie bitte den Grund für Ihre Abwesenheit"
                  />
                </label>
              </div>
            </div>
            <div class="row rowInput" v-if="localEntryData.activity == 'AB'">
              <div class="col-12">
                <select
                  class="form-control"
                  name="absence"
                  v-model="localEntryData.absence"
                  :disabled="localEntryData.status == 'done'"
                >
                  <option
                    v-for="absence in listOfAbsenceReasons"
                    :key="absence.name"
                    :value="absence.id"
                  >
                    {{ absence.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Internes Meeting Select TODO -->
            <div class="row" v-if="localEntryData.activity == 'IM'">
              <div class="col-12">
                <label for="intmeeting">
                  Interne Tätigkeit
                  <InfoBubble
                    infoText="Welche interne Tätigkeit haben Sie ausgeübt? "
                  />
                </label>
              </div>
            </div>
            <div class="row rowInput" v-if="localEntryData.activity == 'IM'">
              <div class="col-12">
                <select
                  class="form-control"
                  name="intmeeting"
                  v-model="localEntryData.intmeeting"
                  :disabled="localEntryData.status == 'done'"
                >
                  <option
                    v-for="meet in listOfInternalMeetings"
                    :key="meet.name"
                    :value="meet.id"
                  >
                    {{ meet.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Auslastung in 25%-Schritten anzeigen -->
            <div v-if="localEntryData.activity != ''" class="row">
              <div class="col-12">
                <!-- Benötigt, damit die Info-bubble richtig platziert wird -->
                <label for="uload">
                  Auslastung
                  <InfoBubble
                    infoText="Wie lange haben Sie mit dieser Tätigkeit verbracht?"
                  />
                </label>
              </div>
            </div>
            <div v-if="localEntryData.activity != ''" class="row rowInput">
              <div class="col-12">
                <select
                  class="form-control"
                  name="uload"
                  v-model="localEntryData.uload"
                  :disabled="localEntryData.status == 'done'"
                >
                  <option value="0.25">25% (2 Stunden)</option>
                  <option value="0.50">50% (4 Stunden)</option>
                  <option value="0.75">75% (6 Stunden)</option>
                  <option value="1.00">100% (8 Stunden)</option>
                </select>
              </div>
            </div>
            <!-- Hier war ein Spacer -->
            <!-- Kommentarfeld anzeigen (Bei Besuch oder Bürotag) -->
            <div class="row" v-if="showComment">
              <div class="col-12">
                <label>
                  <!-- TODO: -->
                  Wichtige Kommentare
                  <!-- ::: Per Mail an andere Benutzer, Hier
                  Rolle/Person auswählen und direkt hinsenden können (gruppen?)-->
                  <InfoBubble
                    infoText="An dieser Stelle können Sie einen wichtigen Kommentar abgeben. "
                  />
                </label>
              </div>
            </div>
            <div class="row rowInput" v-if="showComment">
              <div class="col-2">
                <label for="abt">Empfänger Abteilung:</label>
                <select name="abt" class="form-control">
                  <option>Vertriebsleitung</option>
                  <option>Marketing</option>
                  <option>Geschäftsführung</option>
                  <option>Buchhaltung</option>
                </select>
              </div>
              <div class="col-2">
                <label for="emp">Empfänger wählen</label>
                <select name="emp" class="form-control">
                  <option></option>
                  <option>Nadine Cetl</option>
                  <option>Ivo Leonhardt</option>
                  <option>Bianca John</option>
                </select>
              </div>
            </div>
                       
            <div class="row rowInput" v-if="showComment">
              <div class="col-12">
                <textarea
                  class="form-control"
                  v-model="localEntryData.important_comment"
                  name="important_comment"
                  :disabled="localEntryData.status == 'done'"
                  placeholder="Wichtiger Kommentar"
                ></textarea>
              </div>
            </div>
            <!-- Hier war ein Spacer -->

            <!-- Kommentarfeld anzeigen (Bei Besuch oder Bürotag) -->
            <div class="row" v-if="showComment">
              <div class="col-12">
                <label for="comment">
                  Kommentare (Kontaktdokumentation)
                  <InfoBubble
                    infoText="An dieser Stelle können Sie einen sonstigen Kommentar abgeben. "
                  />
                </label>
              </div>
            </div>
            <div class="row rowInput" v-if="showComment">
              <div class="col-12">
                <textarea
                  class="form-control"
                  v-model="localEntryData.comment"
                  name="comment"
                  :disabled="localEntryData.status == 'done'"
                ></textarea>
              </div>
            </div>
            <!-- Hier war ein Spacer -->

            <!-- Umsatzfeld anzeigen (EVK (beide),Filialbesuch,BesuchBesuch, Verkaufseinsatz) -->
            <!--<div class="row" v-if="showTurnover">
              <div class="col-12">
                <label for="turnover">Umsatz</label>
              </div>
            </div>
            <div class="row rowInput" v-if="showTurnover">
              <div class="col-12">
                <input
                  class="form-control"
                  type="text"
                  name="turnover"
                  :disabled="localEntryData.status == 'done'"
                  v-model="localEntryData.turnover"
                />
              </div>
            </div>-->
            <div style="height: 20px"></div>
            <div class="row" v-if="localEntryData.status != 'done'">
              <div class="col-lg-4 col-sm-12">
                <button
                  class="submitEntryButton btn btn btn-primary"
                  @click="submitEntry('draft')"
                >
                  Als Entwurf Speichern
                  <template v-if="calculatedDays > 1">
                    (<span :class="{ 'text-warning': calculatedDays > 10 }">{{
                      calculatedDays
                    }}</span>
                    Datensätze)</template
                  >
                </button>
              </div>
              <div class="col-lg-4 col-sm-12">
                <button
                  class="submitEntryButton btn btn btn-danger form-control"
                  @click="resetForm()"
                >
                  Eingaben löschen
                </button>
              </div>

              <div class="col-lg-4 col-sm-12">
                <button
                  class="submitEntryButton btn btn btn-success form-control"
                  @click="submitEntry('done')"
                >
                  Speichern &amp; Erledigen
                  <template v-if="calculatedDays > 1">
                    (<span :class="{ 'text-warning': calculatedDays > 10 }">{{
                      calculatedDays
                    }}</span>
                    Datensätze)</template
                  >
                </button>
              </div>
            </div>
          </template>
        </template>
      </Card>
    </template>
  </BaseTemplate>
</template>


<script>
import DateRangePicker from "vue2-daterange-picker";
import Markenwahl from "../rich/Markenwahl.vue";
import Kundenwahl from "../rich/Kundenwahl.vue";
import InfoBubble from "../micro/InfoBubble.vue";
import Statusbalken from "../micro/Statusbalken.vue";

import Loader from "../micro/Loader.vue";
import BaseTemplate from "../master/BaseTemplate.vue";
import Card from "../master/Card.vue";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import axios from "axios";

export default {
  name: "Stundenerfassung",
  components: {
    DateRangePicker,
    Markenwahl,
    Kundenwahl,
    InfoBubble,
    Statusbalken,
    Loader,
    BaseTemplate,
    Card,
  },

  props: {
    // msg: String,
    // loadedEntry: Object,
    Number: String, // Ja, ... wird auf String gecasted.
    cmode: String,
  },
  computed: {
    mode: function () {
      if (this.operationMode === "edit") {
        return "edit";
      } else if (this.operationMode == "create") {
        return "create";
      } else {
        this.addMessage(
          "error",
          "Kritischer Fehler: operationMode muss edit oder create sein! Bitte kontaktieren Sie Ihren Betreiber."
        );
        return null;
      }
    },
    calculatedDays: function () {
      let put = 1000 * 60 * 60 * 24; // Teiler, damit unten Tage rausfallen siehe https://www.w3schools.com/jsref/jsref_parse.asp
      return (
        Date.parse(this.localEntryData.dateRange.endDate) / put -
        Date.parse(this.localEntryData.dateRange.startDate) / put +
        1
      );
    },
    localEntryData: function () {
      // Vorgeladener Eintrag? Diesen nehmen! Kein Eintrag? Leeres Default Modell erstellen! Modell besteht? Dieses zurückgeben!
      if (this.loadedEntry != null && this.loaded) {
        let copy = this.loadedEntry;
        copy.dateRange = {}; // direkt dateRange.startDate initialisieren funktioniert nicht ;)
        copy.dateRange.startDate = this.loadedEntry.range_from;
        copy.dateRange.endDate = this.loadedEntry.range_to;

        if (this.loadedEntry.status == "done") {
          this.addMessage(
            "info",
            "Dieser Eintrag darf nicht mehr verändert werden, weil er auf Erledigt gesetzt wurde"
          );
        }
        return copy;
      }

      if (this.loadedEntry == null && this.loaded && this.mode == "create") {
        this.helperDataMethod(); // schreibt in den Helper ein leeres Default-Modell rein oder gibt den aktuellen Datenstand zurück
        return this.helperData;
      }
      return null;
    },
    showTurnover: function () {
      return false; // DEAKTIVIERT!
      /*if (this.localEntryData.activity == "BE") {
        if (this.localEntryData.visitation == "VEVK") return true;
        if (
          ["VEVK", "VFB", "VVK", "VBB"].includes(this.localEntryData.visitation)
        )
          return true;
      }
      if (
        this.localEntryData.activity == "BT" &&
        this.localEntryData.officeday == "BTK"
      ) {
        if (
          this.localEntryData.evk != "BTKTM" &&
          this.localEntryData.evk != "BTKSG" &&
          this.localEntryData.evk != ""
        )
          return true;
      }
      return false;  Kommentar, sonst meckert Linter*/
    },
    showComment: function () {
      if (
        this.localEntryData.activity == "BE" ||
        this.localEntryData.activity == "BT"
      )
        return true;
      return false;
    },
    showMark: function () {
      if (this.localEntryData == null) return false;

      if (this.localEntryData.activity == "BE") {
        if (!["VPOS", "VJG"].includes(this.localEntryData.visitation))
          return true; // Markenauswahl ist bei Besuch immer aktiv, außer bei Jahresgesprächen und POS Check
      }
      if (
        this.localEntryData.activity == "BT" &&
        this.localEntryData.officeday == "BTK" &&
        this.localEntryData.evk != "BTKTM" &&
        this.localEntryData.evk != "BTKSG" &&
        this.localEntryData.evk != ""
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    showMark: function () {
      // Sorgt dafür, dass der Wert, der Markenauswahl beim Ausblenden selbiger genullt wird.
      if (this.showMark == false) {
        this.localEntryData.compMarken = [];
      }
    },
  },
  data: function () {
    return {
      user: "", // Kommt aus dem Backend
      errors: [],
      infos: [],
      successes: [],
      helperData: null, // Helper für die localEntryData-computed-Variable
      loaded: false,
      id: -1,
      /* Inhalte der Selects */
      listOfActivities: [
        { id: "BE", name: "Besuch" },
        { id: "BT", name: "Büro" },
        { id: "IM", name: "Interne Meetings" },
        { id: "AB", name: "Abwesenheit" },
      ],
      listOfVisitationActivities: [
        { id: "VEVK", name: "EVK" },
        { id: "VPOS", name: "POS Check" },
        { id: "VFB", name: "Filialbesuch" },
        { id: "VBB", name: "Besuch" },
        { id: "VJG", name: "Jahresgespräch" },
        { id: "VNP", name: "Neudepot" },
        { id: "VPS", name: "Produktschulung" },
        { id: "VVK", name: "Verkaufseinsatz" },
        { id: "VNK", name: "Neukundenkontakt" },
        { id: "VSG", name: "Sonstiges" },
      ],
      listOfEVKs: [
        { id: "E1", name: "EVK1" },
        { id: "E2", name: "EVK2" },
        { id: "E3", name: "EVK3" },
        { id: "E4", name: "EVK4" },
        { id: "E5", name: "EVK5" },
      ],
      listOfMarks: [
        { id: "M1", name: "Hugo Boss" },
        { id: "M2", name: "Mandala" },
        { id: "M3", name: "Mandala1" },
        { id: "M4", name: "Mandala2" },
        { id: "M5", name: "Mandala3" },
      ],
      listOfOfficeDayActivities: [
        { id: "BTK", name: "Telefonkontakt" },
        { id: "BAD", name: "Administration" },
        { id: "BWT", name: "Werkstatt" },
        { id: "BSG", name: "Sonstiges" },
      ],
      listOfInternalMeetings: [
        { id: "IMEV", name: "EVK" },
        { id: "IMVM", name: "Vertriebsmeeting" },
        { id: "IMPG", name: "Personalgespräch" },
        { id: "IMMS", name: "Messen" },
        { id: "IMSG", name: "Sonstiges" },
      ],
      listOfAbsenceReasons: [
        { id: "ABUB", name: "Urlaub" },
        { id: "ABKK", name: "Krank" },
        { id: "ABFT", name: "Feiertag" },
        { id: "ABAT", name: "Ausgleichstag" },
        { id: "ABSG", name: "Sonstiges" },
      ],
      /* Ende: Inhalte der Selects */
      /* Datepicker Settings */
      opens: "right", //Ja, dadurch öffnet es sich links..
      startDate: "2019-01-01",
      endDate: "2020-12-31",
      locale: {
        direction: "ltr", //direction of text
        format: "DD.MM.YYYY", //format of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        daysOfWeek: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        monthNames: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember",
        ],
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },
      /* Ende Datepicker Settings */
    };
  },
  filters: {
    pickerdate: function (d) {
      let dat = new Date(d);
      return (
        dat.getUTCDate() +
        "." +
        (parseFloat(dat.getUTCMonth()) + 1) +
        "." +
        dat.getFullYear()
      );
    },
    sqldate: function (d) {
      let dat = new Date(d);
      return (
        dat.getFullYear() +
        "-" +
        (parseFloat(dat.getUTCMonth()) + 1) +
        "-" +
        dat.getUTCDate()
      );
    },
  },
  methods: {
    resetForm: function () {
      this.helperData = null;
      this.helperDataMethod();
    },
    preloadEntryData: function () {
      let cthis = this;

      axios.get("/sanctum/csrf-cookie").then(function () {
        // window.console.log(response);
        if (!isNaN(parseInt(cthis.id))) {
          axios
            .get("/v1/stundenerfassung/" + cthis.id)
            .then(function (data) {
              cthis.loadedEntry = data.data;
              cthis.loaded = true;
            })
            .catch(function (e) {
              cthis.addMessage(
                "error",
                "Beim Laden des Eintrags ist ein Fehler aufgetreten: " +
                  e.response.data.error
              );
              cthis.loaded = false;
            })
            .finally(function () {
              //localEntryData();
            });
        }
      });
    },
    helperDataMethod() {
      if (this.helperData === null)
        this.helperData = {
          compMarken: [], // Für Component 'Markenwahl'
          activity: "", //
          visitation: "", //
          postype: "",
          attendees: 0,
          evk: "",
          mark: "",
          officeday: "",
          comment: "",
          important_comment: "",
          uload: "0.25",
          turnover: "0",
          intmeeting: "",
          absence: "",
          marks: [],
          customer_id: null,
          dateRange: {
            startDate:
              new Date().getFullYear() +
              "-" +
              (parseFloat(new Date().getUTCMonth()) + 1) +
              "-" +
              new Date().getUTCDate(),
            endDate:
              new Date().getFullYear() +
              "-" +
              (parseFloat(new Date().getUTCMonth()) + 1) +
              "-" +
              new Date().getUTCDate(),
          },
          status: null,
        };
    },
    backToList() {
      this.$emit("backToList", null);
    },
    loadUserName() {
      axios.get("/v1/user").then((userdata) => {
        if (userdata.status == 200) {
          this.user = userdata.data.name;
        } else {
          this.addMessage(
            "error",
            "Fehler beim Laden der Benutzerinformationen"
          );
        }
      });
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled =
          date.getTime() < new Date().setDate(new Date().getDate() - 14);
      }
      return classes;
    },
    addMessage(type, text) {
      if (type === "success") {
        if (this.successes.indexOf(text) > -1) return; // avoid duplicates
        this.successes.push(text);
      } else if (type === "error") {
        if (this.errors.indexOf(text) > -1) return;
        this.errors.push(text);
      } else if (type === "info") {
        if (this.infos.indexOf(text) > -1) return;
        this.infos.push(text);
      }
      return;
    },
    NE(input) {
      // NotEmpty
      if (input !== "") return true;
    },
    E(input) {
      return !this.NE(input);
    },
    validateData() {
      this.errors = [];
      this.successes = [];
      this.infos = []; // Validationsbezogene Arrays zurücksetzen
      // Validationkonfiguration (Wäre wohl besser, wenn man das als Prop nutzen könnte.. Kann Vue aber nicht)

      let defaultValidationPatterns = {
        None: null,
        alphaNumericWithSpaces: {
          pattern: /[a-zA-Z0-9\u00E4\u00F6\u00FC\u00C4\u00D6\u00DC\u00df .,]+/g, // [a-zA-Z] mit Umlauten und &szlig; Erlaubt Leerzeichen sowie . und ,
          patternText: "Alphanumerische Zeichen (Alphabet und Zahlen)",
        },
        date: {
          pattern: /(\d{1,2}\.\d{1,2}\.\d{4})/g,
          patternText: "Datum (DD.MM.YYYY)",
        },
        Integer: {
          pattern: /\d*/g,
          patternText: "Zahl",
        },
      };

      let validationData = [
        /*{
          name: "Mitarbeiter",
          Value: this.user,
          M: true,
          Pattern: defaultValidationPatterns.alphaNumericWithSpaces.pattern,
          ContainedIn: "anyString"
        },
        {
          name: "Datum von",
          Value: this.dateRange.startDate,
          M: true,
          Pattern: defaultValidationPatterns.date
        },
        {
          name: "Datum bis",
          Value: this.dateRange.endDate,
          M: true, // der DatePicker setzt das stets automatisch.
          Pattern: defaultValidationPatterns.date
        } Sind beide Prefilled. Muss man schon mit Aufwand kaputt machen - Daher nur Serverseitig validiert,*/
        {
          name: "Aktivität",
          Value: this.localEntryData.activity,
          M: true,
          Pattern: defaultValidationPatterns.None,
          ContainedIn: this.listOfActivities,
        },
        {
          name: "Aktivität vor Ort",
          Value: this.localEntryData.visitation,
          M: "custom", // custom = Validierungscode wird unten angegeben.
          Pattern: defaultValidationPatterns.None,
          ContainedIn: this.listOfVisitationActivities,
        },
        {
          name: "Markenwahl",
          Value: this.localEntryData.compMarken,
          M: "custom",
          Pattern: defaultValidationPatterns.None,
          ContainedIn: "anyString", // Könnte man ggf. die Markenliste einbauen [die muss aber erst an die Markenwahl Komponente durchgereicht werden? TODO!]
        },
        {
          name: "EVK-Typ",
          Value: this.localEntryData.evk,
          M: "custom",
          Pattern: defaultValidationPatterns.None,
          ContainedIn: this.listOfEVKs,
        } /*
        {
          name: "Umsatz",
          Value: this.localEntryData.turnover,
          M: "custom",
          Pattern: defaultValidationPatterns.alphaNumericWithSpaces,
        }, */,
        {
          name: "Anzahl Schulungsteilnehmer",
          Value: this.localEntryData.attendees,
          M: "custom",
          Pattern: defaultValidationPatterns.Integer, // Das sollte Zahl sein, funktioniert aber nicht korrekt.
        },
        {
          name: "POS-Check-Typ",
          Value: this.localEntryData.postype,
          M: "custom",
          ContainedIn: ["VPOSILC", "VPOSSOM"],
          Pattern: defaultValidationPatterns.None,
        },
        {
          name: "Kommentare",
          Value: this.localEntryData.comment,
          M: false,
          Pattern: defaultValidationPatterns.None, // eig. string + spaces
        },
        {
          name: "Wichtige Kommentare",
          Value: this.localEntryData.important_comment,
          M: false,
          Pattern: defaultValidationPatterns.None, // eig. string + spaces
        },
        {
          name: "Auslastung",
          Value: this.localEntryData.uload,
          M: true,
          Pattern: defaultValidationPatterns.alphaNumericWithSpaces,
        },
        {
          name: "Bürotätigkeit",
          Value: this.localEntryData.officeday,
          M: "custom",
          Pattern: defaultValidationPatterns.None,
          ContainedIn: this.listOfOfficeDayActivities,
        },
        {
          name: "Gesprächsthema",
          Value: this.localEntryData.evk,
          M: "custom",
          Pattern: defaultValidationPatterns.None,
        },
        {
          name: "Interne Tätigkeit",
          Value: this.localEntryData.intmeeting,
          M: "custom",
          Pattern: defaultValidationPatterns.None,
        },
        {
          name: "Abwesenheitstyp",
          Value: this.localEntryData.absence,
          M: "custom",
          Pattern: defaultValidationPatterns.None,
        },
        {
          name: "Kunde",
          Value: this.localEntryData.customer_id,
          M: "custom",
          Pattern: defaultValidationPatterns.Integer,
        },

        //  M = Mandatory (true = Immer Pflichtfeld), Pattern = RegExp zur Validierung, ContainedIn = Muss einem der genannten Werte entsprechen
      ];
      let validationErrorMsg = {
        M: "'{field}' ist ein Pflichtfeld und muss ausgefüllt werden!",
        Pattern:
          "Die Eingabe im Feld '{field}' entspricht nicht dem vorgegebenen Muster ({patternText}).",
        ContainedIn: "Die Eingabe im Feld '{field}' ist ungültig.", // Nur relevant bei Selects.
        InvalidMarkCount: "Bitte wählen Sie eine oder mehrere Marken aus",
      };
      validationData.forEach((element) => {
        if (element.Value === undefined || element.Value === null) {
          // Interessanterweise können Strings, die an inputs dran hängen auch undefined sein, wenn sie leer sind (anstelle von erwartetem leeren String)
          element.Value = "";
        }

        if (!Array.isArray(element.Value)) {
          element.Value = element.Value.toString().trim(); // Vorab Trimmen
        }
        /*
        window.console.log("element.name = " + element.name);
        window.console.log(
          "Field:" + element.name + " Value: " + element.Value
        );
  */

        if (element.M == true && element.Value === "") {
          // Todo: TRIM?

          this.addMessage(
            "error",
            validationErrorMsg.M.replace("{field}", element.name)
          );
        }
        if (element.ContainedIn !== undefined) {
          if (element.ContainedIn.indexOf == -1) {
            this.addMessage(
              "error",
              validationErrorMsg.ContainedIn.replace("{field}", element.name)
            );
          }
        }
        // Pattern Validieren (sofern Inhalt > 0)
        if (!this.E(element.Value) && element.Pattern !== null) {
          // None-Pattern wird nicht validiert
          let err = true;
          let m = element.Value.match(element.Pattern.pattern);
          if (m !== null && m !== undefined) {
            if (m[0] == element.Value) {
              // kommt aus dem Match der gleiche Wert heraus wie zuvor eingegeben wurde? Dann fullmatch!
              err = false;
            }
          }
          //window.console.log("Validation");
          //window.console.log(m);
          if (err) {
            this.addMessage(
              "error",
              validationErrorMsg.Pattern.replace(
                "{field}",
                element.name
              ).replace("{patternText}", element.Pattern.patternText)
            );
          }
        }

        if (element.M === "custom") {
          // M enthält einen Validierungsausdruck
          switch (element.name) {
            case "Aktivität vor Ort":
              if (
                this.localEntryData.activity == "BE" &&
                this.E(element.Value)
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
            case "Markenwahl":
              if (this.showMark) {
                let hasSelectedMark = false;

                this.localEntryData.compMarken.forEach((e) => {
                  //window.console.log(e);
                  if (e.selected === true) {
                    hasSelectedMark = true;
                  }
                }); /*
                if(this.localEntryData.compMarken.length > 0) {
                  hasSelectedMark = true;
                  window.console.log(this.localEntryData.compMarken.length);
                } */
                if (!hasSelectedMark) {
                  this.addMessage("error", validationErrorMsg.InvalidMarkCount); // Enthält kein Makro (singleuse Text)
                }
              }
              break;
            case "EVK-Typ":
              if (
                (this.localEntryData.activity == "BE" &&
                  this.localEntryData.visitation == "VEVK") ||
                (this.localEntryData.activity == "BT" &&
                  this.localEntryData.officeday == "BTK" &&
                  this.listOfEVKs.includes(this.localEntryData.evk))
              ) {
                if (this.E(element.Value)) {
                  this.addMessage(
                    "error",
                    validationErrorMsg.M.replace("{field}", element.name)
                  );
                }
              }
              break;
            /* case "Umsatz":
              if (this.showTurnover && this.E(element.Value)) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;*/
            case "Anzahl Schulungsteilnehmer":
              if (
                this.localEntryData.activity == "BE" &&
                this.localEntryData.visitation == "VPS" &&
                (this.E(element.Value) || element.Value == 0)
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
            case "Bürotätigkeit":
              if (
                this.localEntryData.activity == "BT" &&
                this.E(element.Value)
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
            case "Gesprächsthema":
              if (
                this.localEntryData.activity == "BT" &&
                this.localEntryData.officeday == "BTK" &&
                this.E(element.Value)
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
            case "POS-Check-Typ":
              if (
                this.localEntryData.activity == "BE" &&
                this.localEntryData.visitation == "VPOS" &&
                this.E(element.Value)
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
            case "Interne Tätigkeit":
              if (
                this.localEntryData.activity == "IM" &&
                this.E(this.localEntryData.intmeeting)
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
            case "Abwesenheitstyp":
              if (
                this.localEntryData.activity == "AB" &&
                this.E(this.localEntryData.absence)
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
            case "Kunde":
              if (
                (this.localEntryData.activity == "BE" ||
                  (this.localEntryData.activity == "BT" &&
                    this.localEntryData.officeday == "BTK")) &&
                this.localEntryData.customer_id == null
              ) {
                this.addMessage(
                  "error",
                  validationErrorMsg.M.replace("{field}", element.name)
                );
              }
              break;
          }
        }
        window.console.log(this.localEntryData);
        // containedIn vs Mandatory?
      });
      return name + 1;
    },
    submitEntry(whichState) {
      // this für den Zugriff auf Variablen verlinken
      let cthis = this;

      cthis.validateData();

      if (cthis.errors.length > 0) {
        // Infos & Warnungen führen nicht zu einem Abbruch der Anfrage.
        return;
      }

      if (cthis.localEntryData.status == "done") {
        if (whichState == "draft") {
          cthis.addMessage(
            "error",
            "Ein erledigter Datensatz kann nicht als Entwurf gespeichert werden."
          );
          return;
        } else {
          cthis.addMessage(
            "error",
            "Ein erledigter Datensatz darf nicht mehr verändert werden."
          );
          return;
        }
      } else {
        cthis.localEntryData.status = whichState;
      }
      if (cthis.localEntryData.status == null) {
        window.console.log("init " + whichState);
        cthis.localEntryData.status = whichState;
      }

      // Nur die selektierten Marken reinmappen
      let onlySelected = [];
      if (cthis.localEntryData.compMarken !== undefined) {
        // undefined, wenn wir keine Marken haben..
        cthis.localEntryData.compMarken.forEach(function (val) {
          if (val.selected == true) {
            onlySelected.push(parseInt(val.id));
          }
        });
      } // Else wäre: 'Es sind keine Marken im System hinterlegt'

      let parameterMapping = {
        //  range_from: cthis.dateRange.startDate this.pickerdate ,
        //  range_to: cthis.dateRange.endDate  ,
        range_from: cthis.$options.filters.sqldate(
          cthis.localEntryData.dateRange.startDate
        ),
        range_to: cthis.$options.filters.sqldate(
          cthis.localEntryData.dateRange.endDate
        ),
        attendees: cthis.localEntryData.attendees,
        activity: cthis.localEntryData.activity,
        visitation: cthis.localEntryData.visitation,
        postype: cthis.localEntryData.postype,
        absence: cthis.localEntryData.absence,
        officeday: cthis.localEntryData.officeday,
        evk: cthis.localEntryData.evk,
        turnover: cthis.localEntryData.turnover,
        intmeeting: cthis.localEntryData.intmeeting,
        marks: onlySelected,
        comment: cthis.localEntryData.comment,
        important_comment: cthis.localEntryData.important_comment,
        uload: cthis.localEntryData.uload,
        customer_id: cthis.localEntryData.customer_id,
        status: cthis.localEntryData.status, // draft|done
        //user_id: -1, // userid einfügen [Backend soll das tun!]
      };

      if (cthis.mode == "create") {
        axios
          .post("/v1/stundenerfassung", parameterMapping)
          .then(function (response) {
            if (response.status == 200) {
              cthis.addMessage("success", "Eintrag erfolgreich erstellt.");
            }
          })
          .catch(function (error) {
            cthis.addMessage(
              "error",
              "Beim Erstellen des Eintrags ist ein Fehler aufgetreten!"
            );
            //window.console.log(error.response.data.errors);
            for (let inn in error.response.data.errors) {
              //window.console.log(error.response.data.errors[inn][0]);
              cthis.addMessage("error", error.response.data.errors[inn][0]);
            }
          });
      } else if (cthis.mode == "edit") {
        axios
          .put(
            "/v1/stundenerfassung/" + cthis.localEntryData.id,
            parameterMapping
          )
          .then(function (response) {
            if (response.status == 200) {
              cthis.addMessage("success", "Eintrag erfolgreich gespeichert.");
            }
          })
          .catch(function (error) {
            cthis.addMessage(
              "error",
              "Beim Erstellen des Eintrags ist ein Fehler aufgetreten"
            );
            window.console.log(error);
          });
      }
      window.scrollTo(0, 0);
      return false;
    },

    //markenChange(e) {
    //window.console.log(e);
    //  this.localEntryData.compMarken = e;
    //}
  },
  mounted: function () {
    let cthis = this;
    this.$nextTick(function () {
      cthis.id = cthis.$route.params.id;
      cthis.operationMode = cthis.$route.params.cmode;
      if (cthis.mode == "edit") {
        this.loadUserName();
        this.preloadEntryData();
      } else {
        cthis.loaded = true;
      }
    });
  },
};
</script>

<style scoped>
.formSpacer {
  height: 1vh;
}
.spaceHelper {
  margin-bottom: 0.9vh;
}
.rowInput {
  margin-bottom: 1vh;
}
.submitEntryButton {
  width: 100%;
}

@media screen and (max-width: 576px) {
  /* --breakpoint-sm */
  button {
    margin-bottom: 15px;
  }
}

.daterangepicker {
  top: 69px !important;
}
</style>